import React from 'react';
import {TargetCardProps} from './TargeCard';

export const AcquisitionCards = ({title, description}: TargetCardProps) => {
  return (
    <div className="flex flex-col rounded-lg border-gray-200 border p-4 mx-2 grow">
      <div className="text-2xl font-bold text-blue-800">{title}</div>
      <div className="text-sm mt-4">{description}</div>
    </div>
  );
};
