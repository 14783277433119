import React from 'react';
import ReactDropdown from 'react-dropdown';
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/outline';
import {Maybe} from '../../../gql/graphql';

type Props = {
  options: string[];
  onChange: (value: string) => void;
  title?: string;
  placeholder: string;
  value?: Maybe<string>;
};
export const DropDown = ({
  onChange,
  options,
  title,
  placeholder,
  value,
}: Props) => {
  return (
    <>
      {title && (
        <div className="text-gray-600 font-light mx-2 my-2">{title}</div>
      )}
      <ReactDropdown
        className="flex flex-col max-w-xl"
        controlClassName="flex flex-row bg-gray-100 rounded-lg px-6 py-2 items-center justify-between w-full"
        options={options}
        placeholder={placeholder}
        arrowClassName="bg-red-200"
        menuClassName="px-6 cursor-pointer bg-gray-50"
        value={value ?? ''}
        onChange={value => onChange(value.value)}
        arrowClosed={<ChevronDownIcon className="w-8 h-8" />}
        arrowOpen={<ChevronUpIcon className="w-8 h-8" />}
      />
    </>
  );
};
