import React, {SyntheticEvent} from 'react';

type Props = {
  title: string;
  description?: string;
  disabled?: boolean;
  update?: (e: SyntheticEvent) => void;
  showAssignBtn?: boolean;
};
export const TitleBar = ({
  title,
  description,
  disabled,
  update,
  showAssignBtn,
}: Props) => {
  return (
    <div className="w-full flex flex-row bg-white items-center px-4">
      <div className="flex flex-col grow px-4 py-8">
        <div>{description}</div>
        <div className="text-2xl text-black font-bold">{title}</div>
      </div>
      <div className={`${showAssignBtn ? '' : 'hidden'}`}>
        <button
          disabled={disabled}
          className={`rounded-3xl px-8 py-2 text-white mx-8 ${
            disabled ? 'bg-gray-300' : 'bg-green-600'
          }`}
          onClick={async e => update && (await update(e))}>
          Assign Self
        </button>
      </div>
    </div>
  );
};

TitleBar.defaultProps = {
  description: 'Overview',
  disabled: true,
  showAssignBtn: false,
};
