import React, {useEffect, useState} from 'react';
import SideMenu from '../components/SideMenu';
import {useCurrentUser} from '../hooks/useCurrentUser';
import {RotatingLines} from 'react-loader-spinner';
import {UpdatePasswordDialog} from './UpdatePasswordDialog';
import {makeVar, useReactiveVar} from '@apollo/client';
import {RefreshIcon, BellIcon} from '@heroicons/react/outline';
import {Toast} from './Toast';
import {UMF_ADMIN_TOKEN} from '../constants/constants';
import {version} from '../../package.json';

type Props = {
  loading?: boolean;
  children: React.ReactNode;
};

export const setOpenPasswordUpdateDialog = makeVar<boolean>(false);

export const HomePageLayout = ({loading, children}: Props) => {
  const {user} = useCurrentUser();
  const open = useReactiveVar(setOpenPasswordUpdateDialog);

  useEffect(() => {
    if (user?.adminPassword) {
      setOpenPasswordUpdateDialog(true);
    }
  }, [user]);

  return (
    <div className="flex flex-col bg-gray-100 my-auto justify-center bg-gray-100">
      <div className="flex flex-row w-full">
        <Toast />
        <SideMenu />
        <div
          id="user profile conatainer"
          className="bg-white flex flex-row items-center justify-end w-full p-4 h-16 fixed pr-24">
          <div className="mx-8 cursor-pointer">
            <BellIcon className="h-6 w-6" />
          </div>
          <div className="cursor-pointer flex flex-row">
            <div className="px-2">
              <div className="h-6 w-6 rounded-full bg-orange-400" />
            </div>
            <div className="px-2">
              {user?.firstName} {user?.lastName}
            </div>
          </div>
          <div
            className="mx-8 cursor-pointer"
            onClick={() => window?.location?.reload()}>
            <RefreshIcon className="h-6 w-6" />
          </div>
          <div
            className="px-2 cursor-pointer"
            onClick={async () => {
              await localStorage.removeItem(UMF_ADMIN_TOKEN);
              window?.location.reload();
            }}>
            Log out
          </div>
          <div className="mx-4">v{version}</div>
        </div>
        <div className="flex flex-col grow ml-64 mt-16 h-full">
          <UpdatePasswordDialog
            open={open}
            onClose={() => setOpenPasswordUpdateDialog(false)}
          />
          {children}
        </div>
        {loading && (
          <div className="bg-blue-200 ml-64 opacity-60 fixed h-screen w-screen flex items-center justify-center z-40">
            <RotatingLines
              strokeColor="#e27602"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </div>
        )}
      </div>
    </div>
  );
};
