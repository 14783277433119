import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {UPDATE_BORROWER_PHOTO_MUTATION} from '../mutations/udpateBorrowerPhoto';
import {PhotosType} from './useLoanApplication';

type UseUpdatePhotoType = {
  loading: boolean;
  photos: PhotosType[];
  error?: ApolloError;
  updateBorrowerPhotos: (options: MutationFunctionOptions) => void;
};

export const useUpdateBorrowerPhotos = (): UseUpdatePhotoType => {
  const [updateBorrowerPhotos, {data, error, loading}] = useMutation(
    UPDATE_BORROWER_PHOTO_MUTATION,
  );

  return {
    photos: data?.updateBorrowerPhoto?.photos,
    error,
    loading,
    updateBorrowerPhotos,
  };
};
