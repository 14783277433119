import React from 'react';
import {DEFAULT_CURRENCY} from '../../../constants/constants';
import {Maybe} from '../../../gql/graphql';
import {formatMoney} from '../../../utils/format-money';
import {TransactionsTicket} from './TransactionsTicket';
import {PencilAltIcon} from '@heroicons/react/solid';
import {useLoanApplicationDetail} from '../LoanApplicationDetail';

type TransactionsType = {
  title: string;
  amount?: Maybe<number>;
};
type Props = {
  transactions?: TransactionsType[];
  title: string;
  editable?: boolean;
};
export const MobileMoneyTransactions = ({
  transactions,
  title,
  editable,
}: Props) => {
  const {setDialog, dialog, loanApplication} = useLoanApplicationDetail();
  return (
    <div className="p-4 border border-gray-300 rounded-xl w-full">
      <div className="flex flex-row w-full">
        <div className="text-blue-600 font-medium text-lg my-2">{title}</div>
        {editable && (
          <div
            className="h-9 w-9 flex items-center justify-center mx-4 cursor-pointer"
            onClick={() =>
              setDialog({
                ...dialog,
                updateTransactions: {
                  ...dialog?.updateTransactions,
                  open: true,
                  data: loanApplication?.business,
                },
              })
            }>
            <PencilAltIcon className="text-gray-500 w-full h-full px-1" />
          </div>
        )}
      </div>

      <div className="grid grid-cols-2 w-full">
        {transactions?.map(transaction => (
          <TransactionsTicket
            key={transaction.title}
            title={transaction.title}
            description={formatMoney(transaction.amount, DEFAULT_CURRENCY)}
          />
        ))}
      </div>
    </div>
  );
};

MobileMoneyTransactions.defaultProps = {
  editable: false,
};
