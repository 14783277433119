import {gql} from '@apollo/client';

export const MAKE_MANUAL_PAYMENT = gql`
  mutation MakeManualPaymentMutation(
    $collectionData: ManualCollectionInputType!
  ) {
    manualCollection(collectionData: $collectionData) {
      collection {
        id
        amount
      }
    }
  }
`;
