import React, {SyntheticEvent} from 'react';
import {
  PURPOSE_OF_LOAN,
  PURPOSE_OF_LOAN_CATEGORY,
  RATING_NUMBERS,
} from '../../../constants/constants';
import {LoanApplicationCategoryObject} from '../../../gql/graphql';
import {LoanApplicationCategoryType} from '../../../hooks/useUpdateLoanApplicationCategoryUpdate';
import {DetailInLineTitle} from './DetailInLineTitle';
import {DropDown} from './DropDown';

type Props = {
  loanApplicationCategory: LoanApplicationCategoryType;
  setLoanApplicationCategory: (category: LoanApplicationCategoryType) => void;
  handleSubmitLoanApplicationCategory: (e: SyntheticEvent) => void;
};

export const PurposeOfLoanGroup = ({
  loanApplicationCategory,
  setLoanApplicationCategory,
  handleSubmitLoanApplicationCategory,
}: Props) => {
  return (
    <div className="mt-8 mx-4">
      <DetailInLineTitle title="Loan Application" />
      <div className="grid grid-cols-9 gap-2 w-full">
        <div className="col-span-3  mt-4">
          <div className="flex flex-col">
            <label
              htmlFor="purpose-of-loan-category"
              className="text-gray-600 font-light mx-2 my-2">
              Purpose of loan category
            </label>
            <div className="flex flex-row items-center">
              <input
                id="purpose-of-loan-category"
                value={loanApplicationCategory?.purposeOfLoanCategory ?? ''}
                className="border border-gray-300 rounded-md px-4 py-2 w-full"
                onChange={e =>
                  setLoanApplicationCategory({
                    ...loanApplicationCategory,
                    purposeOfLoanCategory: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="col-span-3 mt-4">
          <div className="flex flex-col">
            <label
              htmlFor="purpose-of-loan"
              className="text-gray-600 font-light mx-2 my-2">
              Purpose of loan
            </label>
            <div className="flex flex-row items-center">
              <textarea
                id="purpose-of-loan"
                rows={2}
                value={loanApplicationCategory?.purposeOfLoan ?? ''}
                className="border border-gray-300 rounded-md px-4 py-2 w-full"
                onChange={e =>
                  setLoanApplicationCategory({
                    ...loanApplicationCategory,
                    purposeOfLoan: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="col-span-1  mt-4">
          <DropDown
            placeholder=""
            title="Attitude"
            options={RATING_NUMBERS}
            value={'' + (loanApplicationCategory?.attitude ?? '')}
            onChange={value =>
              setLoanApplicationCategory({
                ...loanApplicationCategory,
                attitude: parseInt(value),
              })
            }
          />
        </div>
        <div className="col-span-1 mt-4  mt-4">
          <DropDown
            placeholder=""
            title="Honesty"
            options={RATING_NUMBERS}
            value={'' + (loanApplicationCategory?.honesty ?? '')}
            onChange={value =>
              setLoanApplicationCategory({
                ...loanApplicationCategory,
                honesty: parseInt(value),
              })
            }
          />
        </div>
        <div className="col-span-1  mt-4">
          <DropDown
            placeholder=""
            title="Availability"
            options={RATING_NUMBERS}
            value={'' + (loanApplicationCategory?.availability ?? '')}
            onChange={value =>
              setLoanApplicationCategory({
                ...loanApplicationCategory,
                availability: parseInt(value),
              })
            }
          />
        </div>
      </div>
      <div className="flex justify-end">
        <button
          className="text-align-end rounded px-8 py-2 bg-blue-600 text-gray-200 mt-8 mx-2"
          onClick={async e => await handleSubmitLoanApplicationCategory(e)}>
          Save
        </button>
      </div>
    </div>
  );
};
