import React from 'react';

export type TargetCardProps = {
  title?: string | number | null;
  description?: string | number | null;
  containerClass?: string;
  circleClass?: string;
};

export const TargetCard = ({title, description}: TargetCardProps) => {
  return (
    <div className="flex flex-col rounded-lg bg-blue-700 p-4 mx-2 mt-8 px-6">
      <div className="flex flex-row">
        <div className="rounded-full h-6 w-6 bg-blue-900 mr-2" />
        <div className="text-gray-300">{title}</div>
      </div>
      <div className="text-gray-100 mt-4 font-medium">{description}</div>
    </div>
  );
};
