import {gql} from '@apollo/client';

export const UPDATE_LENDER_PASSWORD = gql`
  mutation UpdateLenderPassword($currentPassword: String!, $password: String!) {
    updateLenderPassword(
      password: $password
      currentPassword: $currentPassword
    ) {
      message
      success
    }
  }
`;
