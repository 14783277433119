import {ApolloError, useQuery} from '@apollo/client';
import {LoanApplicationType, RefetchType} from './useLoanApplication';
import {PROFILE_MATCHES_QUERY} from '../queries/profileMatches';

type UseLoanApplicationsType = {
  loading: boolean;
  error: ApolloError | undefined;
  profileMatches: LoanApplicationType[];
  refetch: RefetchType;
};

export const useProfileMatches = (id: string): UseLoanApplicationsType => {
  const {data, loading, error, refetch} = useQuery(PROFILE_MATCHES_QUERY, {
    variables: {id},
  });

  return {
    profileMatches: data?.profileMatches,
    loading,
    error,
    refetch,
  };
};
