import {navigate} from 'gatsby';
import React, {ReactNode, useEffect, useState} from 'react';
import _ from 'lodash';
import AccessDenied from '../../components/AccessDenied';
import {HomePageLayout} from '../../components/HomePageLayout';
import {ItemsTable} from '../../components/ItemsTable';
import {Search} from '../../components/Search';
import {TitleBar} from '../../components/TitleBar';
import {invalidInput} from '../../components/Toast';
import {DEFAULT_CURRENCY, ITEMS_PER_PAGE} from '../../constants/constants';
import {
  LoanApplicationObject,
  LoanApplicationStatusesType,
} from '../../gql/graphql';
import {useHasPagePermission} from '../../hooks/useHasPermission';
import {useLoanApplications} from '../../hooks/useLoanApplications';
import {useRefetchOnLocationChange} from '../../hooks/useRefetchOnLocationChange';
import {formatMoney} from '../../utils/format-money';
import Pagination from '../../components/Pagination';
import usePagination from '../../hooks/usePagination';
import {LoanApplicationType} from '../../hooks/useLoanApplication';

type Props = {
  path?: string;
};

const headers = [
  {name: 'ID'},
  {name: 'Business Name'},
  {name: 'Client Name'},
  {name: 'Date Entered Final Review'},
  {name: 'Amount requested'},
  {name: 'Adjusted amount'},
  {name: 'Quoted Principal'},
  {name: 'Officer Name'},
];

export const FinalReview = ({}: Props) => {
  const {
    loanApplications,
    error,
    loading,
    refetch,
    totalCount,
    pageInfo,
    fetchMore,
  } = useLoanApplications([LoanApplicationStatusesType.FinalReview]);

  const {currentPage, totalPages, goToNextPage, goToPreviousPage} =
    usePagination(ITEMS_PER_PAGE, totalCount);

  const [prevApplications, setPrevApplications] = useState<
    LoanApplicationType[] | undefined
  >([]);

  useRefetchOnLocationChange(refetch);

  const [items, setItems] = useState<{[x: string]: string | ReactNode}[]>([]);
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    if (!loanApplications || !Array.isArray(loanApplications)) return;
    if (!_.isEqual(loanApplications, prevApplications)) {
      setItems(getItems(loanApplications));
      setPrevApplications(loanApplications);
    }
  }, [loanApplications]);

  useEffect(() => {
    if (error) {
      invalidInput(
        `An error occurred when loading final review items, ${error.message}`,
      );
    }
  }, [error]);

  const getItems = (_loanApplications?: LoanApplicationObject[]) => {
    if (!_loanApplications || !Array.isArray(_loanApplications)) return [];
    return _loanApplications.map(application => ({
      id: application?.pk,
      businessName: (
        <div
          className="px-2 text-start text-blue-500 cursor-pointer"
          onClick={() => {
            navigate(`${application.id}/detail`);
          }}>
          {application?.business?.name}
        </div>
      ),
      clientName: application.business?.borrower?.nationalIdName,
      date: new Date(application?.stage?.createdAtUtc).toLocaleDateString(),
      amount: formatMoney(application?.loanQuote?.amount, DEFAULT_CURRENCY),
      proposedAmount:
        formatMoney(application?.loanQuote?.proposedAmount, DEFAULT_CURRENCY) ??
        '-',
      principal: formatMoney(
        application?.loanQuote?.principal,
        DEFAULT_CURRENCY,
      ),
      officer: `${application?.finalReviewer?.fullName ?? '-'}`,
    }));
  };

  useEffect(() => {
    if (!loanApplications || !Array.isArray(loanApplications)) return;
    setItems(
      getItems(
        loanApplications?.filter(
          obj =>
            new RegExp(searchText, 'i').test(obj.business?.name ?? '') ||
            new RegExp(searchText, 'i').test(
              obj?.stage?.staff?.firstName ?? '',
            ) ||
            new RegExp(searchText, 'i').test(
              obj?.stage?.staff?.lastName ?? '',
            ) ||
            new RegExp(searchText, 'i').test(
              obj.business?.borrower?.nationalIdName ?? '',
            ) ||
            new RegExp(searchText, 'i').test(
              new Date(obj?.stage?.createdAtUtc).toLocaleDateString() ?? '',
            ),
        ),
      ),
    );
  }, [searchText]);

  const hasPerm = useHasPagePermission();
  if (!hasPerm('/app/final-review')) return <AccessDenied />;

  return (
    <HomePageLayout loading={loading}>
      <div className="flex flex-col grow w-full h-full">
        <TitleBar title="Final Review" />
        <div className="flex flex-col px-4 py-2 bg-white m-4 rounded-lg shadow-md">
          <Search
            searchText={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
          <ItemsTable headers={headers} items={items} />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalCount}
            goToNextPage={(fetchMore, endCursor) =>
              goToNextPage(fetchMore, endCursor)
            }
            goToPreviousPage={(fetchMore, startCursor) =>
              goToPreviousPage(fetchMore, startCursor)
            }
            pageInfo={pageInfo}
            fetchMore={fetchMore}
          />
        </div>
      </div>
    </HomePageLayout>
  );
};
