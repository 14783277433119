import React from 'react';

type Props = {
  checked?: boolean | null;
  title: string;
  setChecked: (checked: boolean) => void;
};

export const Checkbox = ({title, checked, setChecked}: Props) => {
  return (
    <div className="flex items-center mb-1">
      <input
        id={title}
        type="checkbox"
        checked={checked ?? false}
        className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 
            focus:ring-blue-500 dark:focus:ring-blue-600 
            dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        onChange={() => setChecked(!checked)}
      />
      <label htmlFor={title} className="ml-2 text-sm font-medium text-gray-500">
        {title.split('_').join(' ')}
      </label>
    </div>
  );
};
