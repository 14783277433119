import {makeVar, useReactiveVar} from '@apollo/client';
import {XIcon} from '@heroicons/react/outline';
import React from 'react';

type ToastType = {
  open: boolean;
  message: string;
  positive?: boolean;
};
export const showToast = makeVar<ToastType>({
  open: false,
  positive: false,
  message: '',
});

export const Toast = () => {
  const toast = useReactiveVar(showToast);
  const bgColor = toast.positive ? 'bg-green-600' : 'bg-red-600';
  return (
    <>
      {toast.open && (
        <div
          className={`w-full fixed z-40 p-4 ${bgColor} flex flex-row transition-transform duration-600 delay-150 ease-out transform -translate-y-14 ease-in transform translate-y-0`}>
          <div className="grow text-white px-8">{toast.message}</div>
          <button
            className="h-6 w-6 flex items-center justify-center text-white"
            onClick={() => showToast({...toast, open: false})}>
            <XIcon className="w-full h-full" />
          </button>
        </div>
      )}
    </>
  );
};

export const invalidInput = (message: string) => {
  showToast({
    open: true,
    message,
    positive: false,
  });
};

export const validInput = (message: string) => {
  showToast({
    open: true,
    message,
    positive: true,
  });
};
