import {navigate} from 'gatsby';
import React, {ReactNode, useState, useEffect, useMemo} from 'react';
import _ from 'lodash';
import {HomePageLayout} from '../../components/HomePageLayout';
import {ItemsTable} from '../../components/ItemsTable';
import {Search} from '../../components/Search';
import {Tabs} from '../../components/Tabs';
import {TitleBar} from '../../components/TitleBar';
import {showToast} from '../../components/Toast';
import {DEFAULT_CURRENCY, ITEMS_PER_PAGE} from '../../constants/constants';
import {
  LoanApplicationObject,
  LoanApplicationStatusesType,
  Maybe,
} from '../../gql/graphql';
import {useActiveTab} from '../../hooks/useActiveTab';
import {useLoanApplications} from '../../hooks/useLoanApplications';
import {formatMoney} from '../../utils/format-money';
import {PermissionCard} from '../settings/components/PermissionCard';
import {ActionBtn} from './components/ResubmitActionBtn';
import {useRefetchOnLocationChange} from '../../hooks/useRefetchOnLocationChange';
import {LoanApplicationType} from '../../hooks/useLoanApplication';
import Pagination from '../../components/Pagination';
import {useReactiveVar} from '@apollo/client';
import usePagination, {fetchingMore} from '../../hooks/usePagination';

type Props = {
  path?: string;
};

export const Resubmitted = ({}: Props) => {
  const [searchText, setSearchText] = useState<string>('');

  const incompleteHeaders = [
    {name: 'ID'},
    {name: 'Business Name'},
    {name: 'Client Name'},
    {name: 'Date Entered'},
    {name: 'Phone Number'},
    {name: 'Marked for submission'},
    {name: 'Prescreener'},
  ];

  const activeHeaders = [
    {name: 'ID'},
    {name: 'Business Name'},
    {name: 'Client Name'},
    {name: 'Amount Applied'},
    {name: 'Date'},
    {name: 'District'},
    {name: 'Phone Number'},
    {name: 'Action'},
  ];

  const getIncompleteItems = (_resubmitting?: LoanApplicationObject[]) => {
    if (!_resubmitting || !Array.isArray(_resubmitting)) return [];
    return _resubmitting.map(application => {
      return {
        id: application?.pk,
        businessName: (
          <div
            className="px-2 text-start text-blue-500 cursor-pointer"
            onClick={() => {
              navigate(`${application.id}/detail`);
            }}>
            {application?.business?.name}
          </div>
        ),
        clientName: application?.business?.borrower?.name,
        date: new Date(application.createdAtUtc).toLocaleDateString(),
        phone: application?.business?.borrower?.phoneNumber,
        resubmit: (
          <PermissionCard
            permissions={
              (application?.resubmissions?.map(
                resubmission => resubmission?.photoType,
              ) as Maybe<string>[]) ?? []
            }
          />
        ),
        officer: application?.prescreener?.fullName,
      };
    });
  };

  const filterItems = (
    applications?: LoanApplicationObject[],
  ): LoanApplicationObject[] => {
    if (!applications || !Array.isArray(applications)) return [];
    return applications?.filter(
      (obj: any) =>
        new RegExp(searchText, 'i').test(obj.business?.name ?? '') ||
        new RegExp(searchText, 'i').test(
          obj.business?.borrower?.phoneNumber ?? '',
        ) ||
        new RegExp(searchText, 'i').test(obj?.prescreener?.fullName ?? '') ||
        new RegExp(searchText, 'i').test(obj?.business?.borrower?.name ?? '') ||
        new RegExp(searchText, 'i').test(
          new Date(obj?.stage?.createdAtUtc).toLocaleDateString() ?? '',
        ),
    );
  };

  const getActiveItems = (_resubmitted?: LoanApplicationObject[]) => {
    if (!_resubmitted || !Array.isArray(_resubmitted)) return [];
    return _resubmitted.map(application => {
      return {
        id: application?.pk,
        businessName: (
          <div
            className="px-2 text-start text-blue-500 cursor-pointer"
            onClick={() => {
              navigate(`${application.id}/detail`);
            }}>
            {application?.business?.name}
          </div>
        ),
        clientName: application?.business?.borrower?.name,
        amountApplied: formatMoney(
          application?.loanQuote?.principal,
          DEFAULT_CURRENCY,
        ),
        date: new Date(application.createdAtUtc).toLocaleDateString(),
        district: application?.business?.district,
        phone: application?.business?.borrower?.phoneNumber,
        action: <ActionBtn id={application.id} />,
      };
    });
  };

  const [activeTab, setActiveTab] = useState('Incomplete');

  const loanApplicationStatus = useMemo(() => {
    switch (activeTab) {
      case 'Incomplete':
        return LoanApplicationStatusesType.Resubmitting;
      case 'Active':
        return LoanApplicationStatusesType.Resubmitted;
      default:
        return LoanApplicationStatusesType.Resubmitting;
    }
  }, [activeTab]);

  const {
    loanApplications,
    error,
    loading,
    refetch,
    pageInfo,
    fetchMore,
    totalCount,
  } = useLoanApplications([loanApplicationStatus]);

  const {currentPage, totalPages, goToNextPage, goToPreviousPage} =
    usePagination(ITEMS_PER_PAGE, totalCount);

  useEffect(() => {
    if (error) {
      console.error('loan resubmitting', error);
      showToast({
        open: true,
        message:
          'Error occurred when loading loan active resubmission loan applications',
        positive: false,
      });
    }
  }, [error]);

  const [headers, setHeaders] = useState(incompleteHeaders);

  const [items, setItems] = useState<{[x: string]: string | ReactNode}[]>([]);
  const [prevLoanApplications, setPrevLoanApplications] = useState<
    LoanApplicationType[] | undefined
  >(undefined);

  useEffect(() => {
    switch (activeTab) {
      case 'Incomplete':
        setItems(getIncompleteItems(filterItems(loanApplications)));
        return;
      case 'Active':
        setItems(getActiveItems(filterItems(loanApplications)));
        return;
    }
  }, [searchText]);

  useEffect(() => {
    const method =
      activeTab === 'Incomplete' ? getIncompleteItems : getActiveItems;
    if (!_.isEqual(prevLoanApplications, loanApplications)) {
      setItems(method(filterItems(loanApplications)));
      setPrevLoanApplications(loanApplications);
    }
  }, [loanApplications, activeTab]);

  useActiveTab(
    {setTab: setActiveTab, defaultTab: 'Incomplete', tab: activeTab},
    title => {
      setHeaders(title === 'Incomplete' ? incompleteHeaders : activeHeaders);
      setItems(
        title === 'Incomplete'
          ? getIncompleteItems(filterItems(loanApplications))
          : getActiveItems(filterItems(loanApplications)),
      );
      setActiveTab(title);
    },
  );

  useRefetchOnLocationChange(refetch);

  const loadingMore = useReactiveVar(fetchingMore);

  return (
    <HomePageLayout loading={loading || loadingMore}>
      <div className="flex flex-col grow w-full h-full">
        <TitleBar title="Resubmitted Loans" />
        <div className="flex flex-col px-4 py-2 bg-white m-4 rounded-lg shadow-md">
          <Search
            searchText={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
          <div className="w-4/12">
            <Tabs
              tabs={[
                {title: 'Incomplete', active: activeTab === 'Incomplete'},
                {title: 'Active', active: activeTab === 'Active'},
              ]}
              onClick={title => {
                setHeaders(
                  title === 'Incomplete' ? incompleteHeaders : activeHeaders,
                );
                setItems(
                  title === 'Incomplete'
                    ? getIncompleteItems(filterItems(loanApplications))
                    : getActiveItems(filterItems(loanApplications)),
                );
                setActiveTab(title);
              }}
            />
          </div>
          <ItemsTable headers={headers} items={items} />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalCount}
            goToNextPage={(fetchMore, endCursor) =>
              goToNextPage(fetchMore, endCursor)
            }
            goToPreviousPage={(fetchMore, startCursor) =>
              goToPreviousPage(fetchMore, startCursor)
            }
            pageInfo={pageInfo}
            fetchMore={fetchMore}
          />
        </div>
      </div>
    </HomePageLayout>
  );
};
