import React, {useEffect} from 'react';
import {navigate} from '@reach/router';
import {useCurrentUser} from '../hooks/useCurrentUser';

type Props = {
  component: React.ComponentType | React.ElementType;
  location?: {
    pathname?: string;
  };
  [x: string]: any;
};

const PrivateRoute = ({component: Component, location, ...rest}: Props) => {
  const {loading, error, user} = useCurrentUser();

  useEffect(() => {}, [loading, error, user]);

  if (loading) return <div>loading ...</div>;

  if (!user?.id && location?.pathname !== `/app/login`) {
    navigate('/app/login');
    return null;
  }
  return <Component {...rest} />;
};
export default PrivateRoute;
