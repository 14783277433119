import {ApolloError, useQuery} from '@apollo/client';
import {LoanStatesType, PageInfo} from '../gql/graphql';
import {ALL_LOANS} from '../queries/loans';
import {useEdges} from './useEdges';
import {LoanType} from './useLoanApplication';
import {ITEMS_PER_PAGE} from '../constants/constants';

type UseLoanType = {
  loading: boolean;
  error: ApolloError | undefined;
  loans: LoanType[] | undefined;
  fetchMore: any;
  pageInfo: PageInfo;
  totalCount: number;
};

type LoanSortType =
  | 'ID_ASC'
  | 'ID_DESC'
  | 'CREATED_AT_UTC_ASC'
  | 'CREATED_AT_UTC_DESC'
  | 'BUSINESS_ID_ASC'
  | 'BUSINESS_ID_DESC'
  | 'CURRENCY_ASC'
  | 'CURRENCY_DESC'
  | 'LOAN_PURPOSE_ASC'
  | 'LOAN_PURPOSE_DESC'
  | 'ACCEPTED_TERMS_ASC'
  | 'ACCEPTED_TERMS_DESC'
  | 'AGREED_TO_LOAN_AGREEMENT_ASC'
  | 'AGREED_TO_LOAN_AGREEMENT_DESC'
  | 'ACTIVE_ASC'
  | 'ACTIVE_DESC';

export const useAllLoans = (
  statuses?: LoanStatesType[],
  sort?: LoanSortType[],
): UseLoanType => {
  const {data, loading, error, fetchMore} = useQuery(ALL_LOANS, {
    variables: {
      sort: sort ?? ['CREATED_AT_UTC_DESC'],
      statuses,
      first: ITEMS_PER_PAGE,
    },
  });
  const nodes = useEdges(data?.loans);
  return {
    loans: nodes,
    loading,
    error,
    fetchMore,
    pageInfo: data?.loans?.pageInfo,
    totalCount: data?.loans?.totalCount,
  };
};
