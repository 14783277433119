import React from 'react';
import {AcquisitionCards} from './AcquisitionCards';
import {TargetCardProps} from './TargeCard';

type Props = {
  title?: number | string | null;
  data: TargetCardProps[];
};
export const CollectionsBlock = ({title, data}: Props) => {
  return (
    <div className="rounded-lg bg-white py-4 px-1 shadow-md">
      <div className="mx-4 my-2 text-black font-medium">{title}</div>
      <div className="grid grid-cols-2 gap-4 items-center justify-center">
        {data?.map((datum, index) => {
          return (
            <AcquisitionCards
              key={`${datum.title}-${index}`}
              title={datum.title}
              description={datum.description}
            />
          );
        })}
      </div>
    </div>
  );
};
