import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {UPDATE_LENDER_PASSWORD} from '../mutations/auth/updateLenderPassword';

export type LoginResponseType = {
  message: string;
  success: boolean;
  token: string | null;
};
export type UseUpdateLenderPasswordType = {
  updatePassword: (options: MutationFunctionOptions) => any;
  loading: boolean;
  error?: ApolloError;
  data?: LoginResponseType;
};

export const useUpdateLenderPassword = (): UseUpdateLenderPasswordType => {
  const [updatePassword, {loading, error, data}] = useMutation(
    UPDATE_LENDER_PASSWORD,
  );
  return {
    updatePassword,
    loading,
    error,
    data: data?.emailPasswordLogin,
  };
};
