import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {UPDATE_LOAN_APPLICATION_STAGE} from '../mutations/udpateLoanApplicationStage';

type LoanApplicationStageType = {
  stage: string;
  createdAtUtc: string;
  active: boolean;
  comment: string;
};

type UseUpdateLoanApplicationStage = {
  loading: boolean;
  error?: ApolloError;
  stage?: LoanApplicationStageType;
  updateLoanApplicationStage: (options: MutationFunctionOptions) => void;
};

export const useUpdateLoanApplicationStage =
  (): UseUpdateLoanApplicationStage => {
    const [updateLoanApplicationStage, {error, data, loading}] = useMutation(
      UPDATE_LOAN_APPLICATION_STAGE,
    );
    return {
      loading,
      error,
      updateLoanApplicationStage,
      stage: data?.lenderUpdateLoanApplicationStage?.stage,
    };
  };
