import {MutationFunctionOptions} from '@apollo/client';
import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import {DEFAULT_CURRENCY} from '../constants/constants';
import {RolesType} from '../hooks/useRoles';
import {Dialog} from './Dialog';
import {SingleInput} from './SingleInput';

type Props = {
  open: boolean;
  onClose: () => void;
  setLoading: (loading: boolean) => void;
  createPortfolio: (options: MutationFunctionOptions) => void;
  role?: RolesType;
};

type FormDataType = {
  collectionTarget?: string;
  disbursementTarget?: string;
  acquisitionTarget?: string;
  retentionTarget?: string;
};

type FormDataErrorType = {
  [x: string]: any;
};

export const CreatePortfolioDialog = ({
  open,
  onClose,
  setLoading,
  createPortfolio,
  role,
}: Props) => {
  const getInitialFormData = () => {
    if (role) {
      const permissions = role.permissions?.reduce((prev, current) => {
        prev[`permission_${current}`] = true;
        return prev;
      }, {} as any);
      return {role: role.name, ...permissions};
    } else {
      return {role: ''};
    }
  };

  const [formData, setFormData] = useState<FormDataType>({});
  const [formDataErrors, setDataFormErrors] = useState<FormDataErrorType>({});

  const onChange = (e: ChangeEvent<HTMLInputElement>, name: string) => {
    setFormData({...formData, [name]: e.target.value});
    setDataFormErrors({...formDataErrors, [name]: undefined});
  };

  useEffect(() => {
    if (role) {
      setFormData({...getInitialFormData()});
    }
  }, [role]);

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    let invalid = false;
    const errors: any = {};
    if (
      !formData.acquisitionTarget ||
      formData.acquisitionTarget?.length === 0
    ) {
      errors['acquisitionTarget'] = 'Please Enter acquisition target';
      invalid = true;
    }
    if (!formData.collectionTarget || formData.collectionTarget?.length === 0) {
      errors['collectionTarget'] = 'Please Enter collection target';
      invalid = true;
    }
    if (
      !formData.disbursementTarget ||
      formData.disbursementTarget?.length === 0
    ) {
      errors['disbursementTarget'] = 'Please Enter disbursement target';
      invalid = true;
    }

    if (!formData.retentionTarget || formData.retentionTarget?.length === 0) {
      errors['retentionTarget'] = 'Please Enter retention target';
      invalid = true;
    }

    if (invalid) {
      setDataFormErrors(errors);
      setLoading(false);
      return;
    }
    createPortfolio({variables: {portfolio: formData}});
    setLoading(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Create New Targets"
      btnText="Save Targets"
      onSubmit={e => onSubmit(e)}
      description="Create Targets for this Month">
      <div>
        <SingleInput
          title="Disbursement Target"
          value={formData.disbursementTarget ?? ''}
          type="number"
          prefix={DEFAULT_CURRENCY}
          onChange={e => onChange(e, 'disbursementTarget')}
          error={formDataErrors?.disbursementTarget}
        />
        <SingleInput
          title="Acquisition Target"
          value={formData.acquisitionTarget ?? ''}
          type="number"
          // placeholder="How many clients do you want to acquire this month?"
          onChange={e => onChange(e, 'acquisitionTarget')}
          error={formDataErrors?.acquisitionTarget}
        />
        <SingleInput
          title="Collection Target"
          value={formData.collectionTarget ?? ''}
          type="number"
          prefix={DEFAULT_CURRENCY}
          onChange={e => onChange(e, 'collectionTarget')}
          error={formDataErrors?.collectionTarget}
        />
        <SingleInput
          title="Retention Target"
          value={formData.retentionTarget ?? ''}
          type="number"
          // placeholder="Enter how many clients you target to retain this month?"
          onChange={e => onChange(e, 'retentionTarget')}
          error={formDataErrors?.retentionTarget}
        />
      </div>
    </Dialog>
  );
};
