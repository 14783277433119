import {gql} from '@apollo/client';

export const PORTFOLIO_DATA = gql`
  query PortfolioData {
    portfolio {
      id
      disbursementTarget
      acquisitionTarget
      retentionTarget
      collectionTarget
      active
      month
      year
      pk
      newClientsTotal
      acquisitionRate
      differenceFromAcquisitionTarget
      activeClients
      repaymentRate
      differenceFromDisbursementTarget
      disbursementRate
      dueLastMonth
      collectionTargetLastMonth
      collectionRateLastMonth
      totalDisbursed
      outStandingPortfolio
      collectedLastMonth
    }
  }
`;
