import {gql} from '@apollo/client';
import {BORROWER_FIELDS} from '../queries/loanApplication';

export const UPDATE_BORROWER_MUTATION = gql`
  ${BORROWER_FIELDS}
  mutation UpdateBorrowerMutation(
    $borrower: BorrowerType
    $loanApplicationId: ID
  ) {
    updateBorrower(borrower: $borrower, loanApplicationId: $loanApplicationId) {
      borrower {
        ...BorrowerFields
      }
    }
  }
`;
