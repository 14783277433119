import {gql} from '@apollo/client';
import {LOAN_APPLICATION_FRAGMENT} from './loanApplication';

export const LOAN_APPLICATIONS = gql`
  ${LOAN_APPLICATION_FRAGMENT}
  query LoanApplications(
    $stages: [LoanApplicationStatusesType]
    $sort: [LoanApplicationObjectSortEnum]
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    loanApplications(
      stages: $stages
      sort: $sort
      first: $first
      last: $last
      before: $before
      after: $after
    ) {
      edges {
        node {
          ...LoanApplicationFields
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;
