import {
  useMutation,
  ApolloError,
  MutationFunctionOptions,
} from '@apollo/client';
import {DISBURSE_LOAN_MUTATION} from '../mutations/disburseLoan';

type LoanStatusType = {
  createdAtUtc: string;
  status: string;
};

type LoanType = {
  id: string;
  amount: number;
  principal: number;
  statuses: LoanStatusType[];
  createdAtUtc: string;
};

type UseDisburseLoanType = {
  loading: boolean;
  error?: ApolloError;
  disburseLoan: (options: MutationFunctionOptions) => void;
  loan?: LoanType;
  success?: boolean;
  message?: string;
};

export const useDisburseLoan = (): UseDisburseLoanType => {
  const [disburseLoan, {loading, error, data}] = useMutation(
    DISBURSE_LOAN_MUTATION,
  );
  return {
    loading,
    error,
    loan: data?.disburseLoan?.loan,
    success: data?.disburseLoan?.success,
    message: data?.disburseLoan?.message,
    disburseLoan,
  };
};
