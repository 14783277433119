import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {CREATE_COMMENT} from '../mutations/createComment';

type UseCreateCommentType = {
  loading: boolean;
  comment: string;
  error?: ApolloError;
  createComment: (options: MutationFunctionOptions) => void;
};

export const useCreateComment = (): UseCreateCommentType => {
  const [createComment, {data, error, loading}] = useMutation(CREATE_COMMENT);
  return {
    comment: data?.createComment?.comment,
    error,
    loading,
    createComment,
  };
};
