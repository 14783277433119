import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {UPDATE_BUSINESS_PHOTO_MUTATION} from '../mutations/udpateBusinessPhoto';
import {PhotosType} from './useLoanApplication';

type UseUpdatePhotoType = {
  loading: boolean;
  photos: PhotosType[];
  error?: ApolloError;
  updateBusinessPhotos: (options: MutationFunctionOptions) => void;
};

export const useUpdateBusinessPhoto = (): UseUpdatePhotoType => {
  const [updateBusinessPhotos, {data, error, loading}] = useMutation(
    UPDATE_BUSINESS_PHOTO_MUTATION,
  );

  return {
    photos: data?.updateBusinessPhoto?.photos,
    error,
    loading,
    updateBusinessPhotos,
  };
};
