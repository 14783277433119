import React from 'react';
import {navigate} from 'gatsby';
import _ from 'lodash';
import {
  AdjustmentsIcon,
  BriefcaseIcon,
  ChartBarIcon,
  ClipboardCheckIcon,
  CogIcon,
  DocumentReportIcon,
  FilterIcon,
  ReceiptRefundIcon,
  SearchIcon,
} from '@heroicons/react/solid';
import {AppLogo} from './AppLogo';
import {LenderObject, Maybe} from '../gql/graphql';
import {permissions} from '../constants/constants';
import {useHasPagePermission} from '../hooks/useHasPermission';

type IconBtnProps = {
  icon: React.ReactNode;
  title: string;
  href: string;
  active?: boolean;
  hidden?: boolean;
};

export const IconButton = ({
  icon,
  title,
  href,
  active,
  hidden,
}: IconBtnProps) => {
  const styleActive = active
    ? 'bg-gray-900 text-orange-400'
    : 'text-gray-300 hover:text-white hover:bg-gray-900';

  return (
    <a
      href={href}
      className={`${
        hidden ? 'hidden' : ''
      } flex flex-row items-center mt-2 px-2 cursor-pointer py-2 ${styleActive}`}>
      <div className="px-4">{icon}</div>
      <div className="text-wrap ">{title}</div>
    </a>
  );
};

const isActiveRoute = (routeName: string) => {
  if (typeof window !== 'undefined' && routeName === '/app/dashboard') {
    if (window?.location?.pathname == '/app') {
      return true;
    }
  }
  const url = typeof window !== 'undefined' ? window?.location?.href : '';
  const re = new RegExp(routeName);

  return re.test(url);
};

type Props = {
  user?: LenderObject;
};

export default function SideMenu() {
  const hasPerm = useHasPagePermission();
  return (
    <div className="flex flex-col bg-blue-900 w-64 items-center h-screen fixed z-10 pt-8">
      <AppLogo />
      <div className="flex flex-col justify-start my-8 w-full overflow-y-auto">
        <IconButton
          icon={<AdjustmentsIcon className="h-6 w-6" />}
          title="Dashboard"
          active={isActiveRoute('/app/dashboard')}
          href="/app/dashboard"
          hidden={!hasPerm('/app/dashboard')}
        />
        <IconButton
          icon={<SearchIcon className="h-6 w-6" />}
          title="Search"
          active={isActiveRoute('/app/search')}
          href="/app/search"
          hidden={!hasPerm('/app/search')}
        />
        <IconButton
          icon={<FilterIcon className="h-6 w-6" />}
          title="Pre Screening"
          active={isActiveRoute('/app/pre-screening')}
          href="/app/pre-screening"
          hidden={!hasPerm('/app/pre-screening')}
        />
        <IconButton
          icon={<ReceiptRefundIcon className="h-6 w-6" />}
          title="Resubmitted Loans"
          active={isActiveRoute('/app/resubmitted-loans')}
          href="/app/resubmitted-loans"
          hidden={!hasPerm('/app/resubmitted-loans')}
        />
        <IconButton
          icon={<DocumentReportIcon className="h-6 w-6" />}
          title="Due Diligence"
          active={isActiveRoute('/app/due-diligence')}
          href="/app/due-diligence"
          hidden={!hasPerm('/app/due-diligence')}
        />
        <IconButton
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
              />
            </svg>
          }
          active={isActiveRoute('/app/dismissed-loans')}
          title="Dismissed Loans"
          href="/app/dismissed-loans"
          hidden={!hasPerm('/app/dismissed-loans')}
        />
        <IconButton
          icon={<ClipboardCheckIcon className="h-6 w-6" />}
          title="Final Review"
          active={isActiveRoute('/app/final-review')}
          href="/app/final-review"
          hidden={!hasPerm('/app/final-review')}
        />
        <IconButton
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
              />
            </svg>
          }
          title="Disbursed Loans"
          active={isActiveRoute('/app/disburse')}
          href="/app/disburse"
          hidden={!hasPerm('/app/disburse')}
        />
        <IconButton
          icon={<BriefcaseIcon className="h-6 w-6" />}
          title="Portfolio Manager"
          active={isActiveRoute('/app/portfolio')}
          href="/app/portfolio"
          hidden={!hasPerm('/app/portfolio')}
        />
        <IconButton
          icon={<ChartBarIcon className="h-6 w-6" />}
          title="Reports"
          active={isActiveRoute('/app/reports')}
          href="/app/reports"
          hidden={!hasPerm('/app/reports')}
        />
        <IconButton
          icon={<CogIcon className="h-6 w-6" />}
          title="Settings"
          active={isActiveRoute('/app/settings')}
          href="/app/settings"
          hidden={!hasPerm('/app/settings')}
        />
      </div>
    </div>
  );
}
