import {gql} from '@apollo/client';

export const UPDATE_BORROWER_PHOTO_MUTATION = gql`
  mutation UpdateBorrowerPhotoMutation($photos: [UpdateBorrowerPhotoType]) {
    updateBorrowerPhoto(photos: $photos) {
      photos {
        id
        url
        status
        photoType
        comment
      }
    }
  }
`;
