import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import {DEFAULT_CURRENCY} from '../constants/constants';
import {useManualCollection} from '../hooks/useManualCollection';
import {Dialog} from './Dialog';
import {useLoanApplicationDetail} from './LoanApplicationDetail/LoanApplicationDetail';
import {SingleInput} from './SingleInput';
import {showToast} from './Toast';

type Props = {
  open: boolean;
  onClose: () => void;
  setLoading: (loading: boolean) => void;
};

type FormDataType = {
  amount?: string;
  transactionId?: string;
};

type FormDataErrorType = {
  [x: string]: any;
};

export const AddManualPaymentDialog = ({open, onClose, setLoading}: Props) => {
  const getInitialFormData = () => {
    return {
      amount: '',
      transactionId: '',
    };
  };

  const [formData, setFormData] = useState<FormDataType>({});
  const [formDataErrors, setDataFormErrors] = useState<FormDataErrorType>({});

  const [submitting, setSubmitting] = useState<boolean>(false);

  const {manualCollection, loading} = useManualCollection();

  const {loanApplication} = useLoanApplicationDetail();

  const {business} = loanApplication ?? {};

  const onChange = (e: ChangeEvent<HTMLInputElement>, name: string) => {
    setFormData({...formData, [name]: e.target.value});
    setDataFormErrors({...formDataErrors, [name]: undefined});
  };

  useEffect(() => {
    if (business) {
      setFormData({...getInitialFormData()});
    }
  }, [business]);

  useEffect(() => {
    if (!submitting && !loading) setLoading(false);
    if (submitting || loading) setLoading(true);
  }, [submitting, loading]);

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setSubmitting(true);
    let invalid = false;
    const errors: any = {};
    if (!formData.amount || formData.amount?.length === 0) {
      errors['amount'] = 'Please Enter amount';
      invalid = true;
    }
    if (!formData.transactionId || formData.transactionId?.length === 0) {
      errors['transactionId'] = 'Please Enter Transaction ID';
      invalid = true;
    }

    if (invalid) {
      setDataFormErrors(errors);
      setLoading(false);
      return;
    }
    try {
      const collectionData = {
        ...formData,
        loanId: loanApplication?.loan?.id,
        currency: DEFAULT_CURRENCY,
        phoneNumber: business?.borrower?.phoneNumber,
        payerMessage: 'UMF Loan USSD Payment',
        payeeNote: 'UMF Loan USSD Payment',
      };

      await manualCollection({
        variables: {collectionData},
      });
      showToast({
        open: true,
        message: 'Manual Payment has been added successfully',
        positive: true,
      });
    } catch (error: any) {
      console.error(error);
      showToast({
        open: true,
        message: `Manual Payment failed: ${error.message}`,
        positive: false,
      });
    } finally {
      setSubmitting(false);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Add USSD Payment"
      btnText="Add Payment"
      onSubmit={async e => await onSubmit(e)}
      description="Add USSD Manual Payment">
      <div>
        <SingleInput
          title="Transaction ID"
          value={formData.transactionId ?? ''}
          onChange={e => onChange(e, 'transactionId')}
          error={formDataErrors?.transactionId}
        />
        <SingleInput
          title="Amount"
          value={formData.amount ?? ''}
          type="number"
          prefix={DEFAULT_CURRENCY}
          // placeholder="How many clients do you want to acquire this month?"
          onChange={e => onChange(e, 'amount')}
          error={formDataErrors?.amount}
        />
      </div>
    </Dialog>
  );
};
