import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {LenderObject} from '../gql/graphql';
import {
  SAVE_USER_MUTATION,
  SAVE_USER_UPDATE_CACHE,
} from '../mutations/saveUser';

type UseSaveUserType = {
  loading: boolean;
  error?: ApolloError;
  data?: LenderObject;
  saveUser: (options: MutationFunctionOptions) => void;
};
export const useSaveUser = (): UseSaveUserType => {
  const [saveUser, {data, loading, error}] = useMutation(
    SAVE_USER_MUTATION,
    SAVE_USER_UPDATE_CACHE,
  );
  return {
    data: data?.createLender?.lender,
    loading,
    error,
    saveUser,
  };
};
