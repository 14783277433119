import React from 'react';
import {LoanApplicationObject} from '../../../gql/graphql';
import {
  PhotoStatusesType,
  useLoanApplicationDetail,
} from '../LoanApplicationDetail';
import {Comments} from './Comments';
import {MobileNumber} from './MobileMoneyNumber';
import {Photos} from './Photos';
import {VerificationLinks} from './VerificationLinks';

type Props = {
  setDialog: (object: {
    [x: string]: {
      open?: boolean;
      data?: any;
      loading?: boolean;
    };
  }) => void;
  loanApplication: LoanApplicationObject;
  getComments: () => {[x: string]: string | JSX.Element}[];
  getContactAttempts: () => {[x: string]: string | JSX.Element}[];
  photoStatuses: PhotoStatusesType;
  dialog: {
    [x: string]: {
      open?: boolean;
      data?: any;
      loading?: boolean;
    };
  };
};
export const DueDiligenceLinksGroup = () => {
  const {
    setDialog,
    loanApplication,
    getComments,
    getContactAttempts,
    photoStatuses,
    dialog,
  } = useLoanApplicationDetail();

  return (
    <>
      <Photos
        photos={loanApplication?.business?.borrower?.photos}
        setLoading={loading =>
          setDialog({
            ...dialog,
            photo: {
              ...dialog?.photo,
              loading,
            },
          })
        }
        setDialog={setDialog}
        photoStatuses={photoStatuses}
        title="User Photos"
        photoGroup="BORROWER"
        dialog={dialog}
      />
      <Photos
        photos={loanApplication?.business?.photos}
        setLoading={loading =>
          setDialog({
            ...dialog,
            photo: {
              ...dialog?.photo,
              loading,
            },
          })
        }
        setDialog={setDialog}
        photoStatuses={photoStatuses}
        title="Business Photos"
        dialog={dialog}
      />

      <MobileNumber />
      <MobileNumber loginNumber title="Number the borrower used to login" />
      <div className="w-full px-8 py-4 mt-4">
        <VerificationLinks />
      </div>
      <div className="w-full px-8 py-4 mt-4">
        <Comments
          title="Comments"
          headers={['Note', 'Date', 'Staff']}
          addComment={() =>
            setDialog({
              ...dialog,
              comment: {
                ...dialog?.comment,
                open: true,
                data: loanApplication?.id,
              },
            })
          }
          data={getComments()}
        />
      </div>
      <div className="w-full px-8 py-4 mt-4">
        <Comments
          title="Contact Attempts"
          headers={['Channel', 'Date', 'Staff']}
          data={getContactAttempts()}
          addComment={() =>
            setDialog({
              ...dialog,
              contactAttempts: {
                ...dialog?.contactAttempts,
                open: true,
                data: loanApplication?.id,
              },
            })
          }
        />
      </div>
    </>
  );
};
