import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {UPDATE_LOAN_QUOTE_MUTATION} from '../mutations/updateLoanQuote';
import {LoanQuoteType} from './useLoanApplication';

type UseUpdateLoanQuoteType = {
  loading: boolean;
  error?: ApolloError;
  updateLoanQuote: (options: MutationFunctionOptions) => void;
  quote?: LoanQuoteType;
};
export const useUpdateLoanQuote = (): UseUpdateLoanQuoteType => {
  const [updateLoanQuote, {loading, error, data}] = useMutation(
    UPDATE_LOAN_QUOTE_MUTATION,
  );
  return {
    loading,
    error,
    quote: data?.updateLoanQuote?.quote,
    updateLoanQuote,
  };
};
