import {
  useMutation,
  ApolloError,
  MutationFunctionOptions,
} from '@apollo/client';
import {CREATE_PORTFOLIO} from '../mutations/createPortfolio';
import {PortfolioType} from './usePorfolio';

type UseCreatePortfolioType = {
  loading: boolean;
  error?: ApolloError;
  createPortfolio: (options: MutationFunctionOptions) => void;
  portfolio?: PortfolioType;
};

export const useCreatePortfolio = (): UseCreatePortfolioType => {
  const [createPortfolio, {error, data, loading}] =
    useMutation(CREATE_PORTFOLIO);
  return {
    createPortfolio,
    error,
    loading,
    portfolio: data?.createPortfolio?.portfolio,
  };
};
