import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {CREATE_VISIT_ATTEMPT} from '../mutations/createVisitAttempt';

export type VisitAttemptsType = {};

type UseCreateVisitAttemptType = {
  loading: boolean;
  visitAttempt: VisitAttemptsType;
  error?: ApolloError;
  createVisitAttempt: (options: MutationFunctionOptions) => void;
};

export const useCreateVisitAttempt = (): UseCreateVisitAttemptType => {
  const [createVisitAttempt, {data, error, loading}] =
    useMutation(CREATE_VISIT_ATTEMPT);
  return {
    visitAttempt: data?.createVisitAttempts?.visitAttempt,
    error,
    loading,
    createVisitAttempt,
  };
};
