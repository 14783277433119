import {gql} from '@apollo/client';
import {ROLES_QUERY} from '../queries/roles';

export const DELETE_ROLE = gql`
  mutation DeleteRoleMutation($role: String) {
    deleteRole(role: $role) {
      success
      role {
        id
        name
      }
    }
  }
`;

export const DELETE_ROLE_OPTIONS = {
  update(
    cache: any,
    {
      data: {
        deleteRole: {role},
      },
    }: any,
  ) {
    try {
      const {
        roles: {edges},
      } = cache.readQuery({query: ROLES_QUERY});
      const newEdges = edges.filter(({node}: any) => {
        return node.name !== role.name;
      });
      cache.writeQuery({
        query: ROLES_QUERY,
        data: {
          roles: {
            edges: newEdges,
          },
        },
      });
    } catch (e) {
      console.error(
        'an error ocurred when updating delete mutation options',
        e,
      );
    }
  },
};
