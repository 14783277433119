import React from 'react';
import {RotatingLines} from 'react-loader-spinner';
import {Toast} from './Toast';

type Props = {
  loading?: boolean;
  children: React.ReactNode;
};
export const LoginLayout = ({loading, children}: Props) => {
  return (
    <>
      <Toast />
      <div className="flex flex-col bg-gray-100 my-auto justify-center h-screen items-center">
        {children}
        {loading && (
          <div className="bg-blue-200 opacity-60 fixed h-screen w-screen flex items-center justify-center">
            <RotatingLines
              strokeColor="#e27602"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </div>
        )}
      </div>
    </>
  );
};
