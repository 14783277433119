import {gql} from '@apollo/client';
export const LENDER_FIELDS = gql`
  fragment LenderFields on LenderObject {
    id
    pk
    firstName
    lastName
    dateOfBirth
    phoneNumber
    email
    roles
    permissions
    fullName
  }
`;

export const ALL_USERS = gql`
  ${LENDER_FIELDS}
  query GetAllUser {
    lenders {
      edges {
        node {
          ...LenderFields
        }
      }
    }
  }
`;
