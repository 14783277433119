import React from 'react';
import {Router} from '@reach/router';
import Login from '../routes/Login';
import PrivateRoute from '../components/PrivateRoute';
import {Prescreening} from '../routes/prescreening';
import {Dashboard} from '../routes/dashboard';
import {PrescreeningDetail} from '../routes/prescreening/PrescreeningDetail';
import {DueDiligence} from '../routes/due-diligence';
import {Resubmitted} from '../routes/resubmitted';
import {FinalReview} from '../routes/final-review';
import {Dismissed} from '../routes/dismissed';
import {Portfolio} from '../routes/portfolio-manager';
import {Reports} from '../routes/reports';
import {Settings} from '../routes/settings';
import {Disburse} from '../routes/disburse';
import {DueDiligenceDetail} from '../routes/due-diligence/DueDiligenceDetail';
import {DismissedLoanDetail} from '../routes/dismissed/DismissedLoanDetail';
import {FinalReviewDetail} from '../routes/final-review/FinalReviewDetail';
import {DisbursedLoanDetail} from '../routes/disburse/DisbursedLoanDetail';
import {PortfolioDetail} from '../routes/portfolio-manager/PortfolioLoanDetail';
import {Search} from '../routes/search';

type Props = {
  path?: string;
  children?: React.ReactElement | React.ReactElement[];
};

function Empty({children}: Props) {
  return <>{children}</>;
}

const App = () => {
  return (
    <Router>
      <PrivateRoute path="/app" component={Dashboard} />
      <PrivateRoute path="/app/dashboard" component={Dashboard} />
      <Login path="/app/login" />
      <Empty path="/app/pre-screening">
        <PrivateRoute path="/" component={Prescreening} />
        <PrivateRoute
          path="/:applicationId/detail"
          component={PrescreeningDetail}
        />
      </Empty>
      <Empty path="/app/resubmitted-loans">
        <PrivateRoute path="/" component={Resubmitted} />
        <PrivateRoute
          path="/:applicationId/detail"
          component={PrescreeningDetail}
        />
      </Empty>
      <Empty path="/app/due-diligence">
        <PrivateRoute path="/" component={DueDiligence} />
        <PrivateRoute
          path="/:applicationId/detail"
          component={DueDiligenceDetail}
        />
      </Empty>
      <Empty path="/app/final-review">
        <PrivateRoute path="/" component={FinalReview} />
        <PrivateRoute
          path="/:applicationId/detail"
          component={FinalReviewDetail}
        />
      </Empty>
      <Empty path="/app/dismissed-loans">
        <PrivateRoute path="/" component={Dismissed} />
        <PrivateRoute
          path="/:applicationId/detail"
          component={DismissedLoanDetail}
        />
      </Empty>
      <Empty path="/app/portfolio">
        <PrivateRoute path="/" component={Portfolio} />
        <PrivateRoute
          path="/:applicationId/detail"
          component={PortfolioDetail}
        />
      </Empty>
      <Empty path="/app/reports">
        <PrivateRoute path="/" component={Reports} />
        <PrivateRoute
          path="/:applicationId/detail"
          component={PrescreeningDetail}
        />
      </Empty>
      <Empty path="/app/settings">
        <PrivateRoute path="/" component={Settings} />
        <PrivateRoute
          path="/:applicationId/detail"
          component={PrescreeningDetail}
        />
      </Empty>
      <Empty path="/app/disburse">
        <PrivateRoute path="/" component={Disburse} />
        <PrivateRoute
          path="/:applicationId/detail"
          component={DisbursedLoanDetail}
        />
      </Empty>
      <Empty path="/app/search">
        <PrivateRoute path="/" component={Search} />
        <PrivateRoute
          path="/:applicationId/detail"
          component={PortfolioDetail}
        />
      </Empty>
    </Router>
  );
};

export default App;
