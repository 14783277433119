import {gql} from '@apollo/client';
import {ALL_USERS} from '../queries/lenders';

export const SAVE_USER_MUTATION = gql`
  mutation SaveUserMutation(
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $email: String!
    $dateOfBirth: Date!
    $roles: [RoleType]
    $password: String
  ) {
    createLender(
      lender: {
        firstName: $firstName
        lastName: $lastName
        phoneNumber: $phoneNumber
        email: $email
        dateOfBirth: $dateOfBirth
        adminPassword: true
        password: $password
        roles: $roles
      }
    ) {
      lender {
        id
        firstName
        lastName
        dateOfBirth
        phoneNumber
        email
        roles
        permissions
        disbursementLimit
      }
    }
  }
`;

export const SAVE_USER_UPDATE_CACHE = {
  update(
    cache: any,
    {
      data: {
        createLender: {lender},
      },
    }: any,
  ) {
    try {
      const {
        lenders: {edges},
      } = cache.readQuery({query: ALL_USERS});
      const newEdges = edges.filter(
        ({node}: any) => node.phoneNumber !== lender.phoneNumber,
      );
      cache.writeQuery({
        query: ALL_USERS,
        data: {
          lenders: {
            edges: newEdges.concat([
              {node: {__typename: 'LenderObject', ...lender}},
            ]),
          },
        },
      });
    } catch (e) {
      //move on from error
      console.error('an error when updating mutation', e);
    }
  },
};
