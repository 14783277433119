import React, {SyntheticEvent} from 'react';
import {DEFAULT_CURRENCY} from '../../../constants/constants';
import {LoanApplicationObject} from '../../../gql/graphql';
import {formatMoney} from '../../../utils/format-money';
import {Checkbox} from '../../checkbox';
import {NewQuoteType} from '../LoanApplicationDetail';
import {DetailInLineTitle} from './DetailInLineTitle';

type Props = {
  newQuote: NewQuoteType;
  loanApplication: LoanApplicationObject;
  setNewQuote: (newQuote: NewQuoteType) => void;
  handleSaveQuote: (e: SyntheticEvent) => void;
};

export const AdjustLoanGroup = ({
  loanApplication,
  newQuote,
  setNewQuote,
  handleSaveQuote,
}: Props) => {
  return (
    <div className="mt-8 mx-4">
      <DetailInLineTitle title="Adjust Loan Amount" />
      <form className="grid grid-cols-3 gap-4 mt-2">
        <div className="flex flex-col">
          <span className="text-gray-600 font-light mx-2 my-2">
            Requested Amount
          </span>
          <div className="rounded bg-gray-200 w-full py-2 px-4">
            {formatMoney(
              loanApplication?.loanQuote?.computedPrincipal,
              DEFAULT_CURRENCY,
            )}
          </div>
        </div>

        <div className="flex flex-col">
          <label
            htmlFor="proposed-loan-amount"
            className="text-gray-600 font-light mx-2 my-2">
            Proposed Amount
          </label>
          <div className="flex flex-row items-center">
            <span className="px-4">{DEFAULT_CURRENCY} </span>
            <input
              id="proposed-loan-amount"
              type="number"
              value={newQuote.amount}
              className="border border-gray-300 rounded-md px-4 py-2"
              onChange={e => setNewQuote({amount: e.target.value})}
            />
          </div>
          {newQuote?.error && (
            <span className="py-2 text-red-400">{newQuote?.error}</span>
          )}
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="proposed-loan-amount"
            className="text-gray-600 font-light mx-2 my-2">
            Loan Term Days
          </label>
          <div className="flex flex-row items-center">
            <input
              id="proposed-loan-amount"
              type="number"
              value={newQuote.loanTerm}
              className="border border-gray-300 rounded-md px-4 py-2"
              onChange={e => setNewQuote({loanTerm: e.target.value})}
            />
            <div className="px-2">Days</div>
          </div>
          {newQuote?.termError && (
            <span className="py-2 text-red-400">{newQuote?.termError}</span>
          )}
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="proposed-loan-amount"
            className="text-gray-600 font-light mx-2 my-2">
            Accepted Terms
          </label>
          <div className="mx-2">
            <Checkbox
              title={loanApplication?.acceptedTerms ? 'Yes' : 'No'}
              checked={loanApplication?.acceptedTerms}
              setChecked={() => {}}
            />
          </div>
        </div>
      </form>
      <div className="flex justify-end">
        <button
          className="text-align-end rounded px-8 py-2 bg-blue-600 text-gray-200 mt-8 mx-2"
          onClick={async e => await handleSaveQuote(e)}>
          Save
        </button>
      </div>
    </div>
  );
};
