import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {BorrowerObject} from '../gql/graphql';
import {UPDATE_BORROWER_MUTATION} from '../mutations/udpateBorrower';
import {PhotosType} from './useLoanApplication';

type UseUpdatePhotoType = {
  loading: boolean;
  borrower: BorrowerObject;
  error?: ApolloError;
  updateBorrower: (options: MutationFunctionOptions) => void;
};

export const useUpdateBorrower = (): UseUpdatePhotoType => {
  const [updateBorrower, {data, error, loading}] = useMutation(
    UPDATE_BORROWER_MUTATION,
  );

  return {
    borrower: data?.updateBorrower,
    error,
    loading,
    updateBorrower,
  };
};
