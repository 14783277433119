import {gql} from '@apollo/client';

export const CREATE_PORTFOLIO = gql`
  mutation CreatePortfolioMutation($portfolio: PortfolioInputType) {
    createPortfolio(portfolio: $portfolio) {
      portfolio {
        id
        acquisitionRate
        collectionTarget
        retentionTarget
        disbursementTarget
      }
    }
  }
`;
