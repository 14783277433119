import React, {ReactNode, useEffect, useState} from 'react';
import _ from 'lodash';
import {HomePageLayout} from '../../components/HomePageLayout';
import {TitleBar} from '../../components/TitleBar';
import {ItemsTable} from '../../components/ItemsTable';
import {Search} from '../../components/Search';
import {useLoanApplications} from '../../hooks/useLoanApplications';
import {navigate} from 'gatsby';
import {showToast} from '../../components/Toast';
import {DEFAULT_CURRENCY, ITEMS_PER_PAGE} from '../../constants/constants';
import {LoanApplicationType} from '../../hooks/useLoanApplication';
import {formatMoney} from '../../utils/format-money';
import {useHasPagePermission} from '../../hooks/useHasPermission';
import AccessDenied from '../../components/AccessDenied';
import {LoanApplicationStatusesType} from '../../gql/graphql';
import {useRefetchOnLocationChange} from '../../hooks/useRefetchOnLocationChange';
import Pagination from '../../components/Pagination';
import usePagination, {fetchingMore} from '../../hooks/usePagination';
import {useReactiveVar} from '@apollo/client';

type Props = {
  path?: string;
};

export const Prescreening = ({}: Props) => {
  const headers = [
    {name: 'ID'},
    {name: 'Business Name'},
    {name: 'Client Name'},
    {name: 'Date'},
    {name: 'Amount applied'},
    {name: 'Phone Number'},
    {name: 'Assigned To'},
  ];

  const getItems = (loanApplications?: LoanApplicationType[]) => {
    if (!loanApplications || !Array.isArray(loanApplications)) {
      return [];
    }
    return loanApplications.map(application => {
      return {
        id: application.pk,
        businessName: (
          <div
            className="px-2 text-start text-blue-500 cursor-pointer"
            onClick={() => {
              navigate(`${application.id}/detail`);
            }}>
            {application?.business?.name}
          </div>
        ),
        clientName: application?.business?.borrower?.name,
        date: new Date(application.createdAtUtc).toLocaleDateString(),
        amount: formatMoney(application?.loanQuote?.amount, DEFAULT_CURRENCY),
        phone: application?.business?.borrower?.phoneNumber,
        assignee: application.prescreener?.fullName ?? '-',
      };
    });
  };

  const [prevLoanApplications, setPrevLoanApplications] = useState<
    LoanApplicationType[] | undefined
  >();

  const loadingMore = useReactiveVar(fetchingMore);

  const [items, setItems] = useState<{[x: string]: string | ReactNode}[]>([]);

  const {
    loanApplications,
    error: loanApplicationsError,
    loading: loadingLoanApplications,
    refetch,
    totalCount,
    pageInfo,
    fetchMore,
  } = useLoanApplications([
    LoanApplicationStatusesType.PreScreening,
    LoanApplicationStatusesType.Submitted,
  ]);

  useRefetchOnLocationChange(refetch);

  useEffect(() => {
    if (loanApplicationsError) {
      console.error('loan applications', loanApplicationsError);
      showToast({
        open: true,
        message: 'Error occurred when loading loan applications',
        positive: false,
      });
    }
  }, [loanApplicationsError]);

  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    if (!loanApplications || !Array.isArray(loanApplications)) return;
    setItems(
      getItems(
        loanApplications?.filter(
          obj =>
            new RegExp(searchText, 'i').test(obj.business?.name ?? '') ||
            new RegExp(searchText, 'i').test(
              obj?.business?.borrower?.phoneNumber ?? '',
            ) ||
            new RegExp(searchText, 'i').test(
              obj.business?.borrower?.name ?? '',
            ) ||
            new RegExp(searchText, 'i').test(
              new Date(obj?.createdAtUtc).toLocaleDateString() ?? '',
            ),
        ),
      ),
    );
  }, [searchText]);

  useEffect(() => {
    if (!loanApplications || !Array.isArray(loanApplications)) return;
    if (!_.isEqual(loanApplications, prevLoanApplications)) {
      setItems(getItems(loanApplications));
      setPrevLoanApplications(loanApplications);
    }
  }, [loanApplications]);
  const hasPerm = useHasPagePermission();

  const {currentPage, totalPages, goToNextPage, goToPreviousPage} =
    usePagination(ITEMS_PER_PAGE, totalCount);

  if (!hasPerm('/app/pre-screening')) return <AccessDenied />;

  return (
    <HomePageLayout loading={loadingLoanApplications || loadingMore}>
      <div className="flex flex-col grow w-full h-full">
        <TitleBar title="Pre Screening" />
        <div className="flex flex-col px-4 py-2 bg-white m-4 rounded-lg shadow-md">
          <Search
            searchText={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
          <ItemsTable headers={headers} items={items} />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalCount}
            goToNextPage={(fetchMore, endCursor) =>
              goToNextPage(fetchMore, endCursor)
            }
            goToPreviousPage={(fetchMore, startCursor) =>
              goToPreviousPage(fetchMore, startCursor)
            }
            pageInfo={pageInfo}
            fetchMore={fetchMore}
          />
        </div>
      </div>
    </HomePageLayout>
  );
};
