import React, {SyntheticEvent} from 'react';
import {PencilAltIcon} from '@heroicons/react/outline';

type Props = {
  onClick: (e: SyntheticEvent) => void;
};
export const AddComment = ({onClick}: Props) => {
  return (
    <div
      className="rounded-xl mx-8 my-5 bg-gray-100 items-center  px-8 py-2 flex flex-row max-w-2xl cursor-pointer"
      onClick={onClick}>
      <div className="h-6 w-6">
        <PencilAltIcon className="h-full w-full text-gray-400" />
      </div>
      <div className="text-base text-gray-400 px-4">Note</div>
    </div>
  );
};
