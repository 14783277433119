import {gql} from '@apollo/client';

export const UPDATE_LOAN_APPLICATION_CATEGORY = gql`
  mutation UpdateLoanApplicationCategoryMutation(
    $loanApplicationId: ID!
    $category: LoanApplicationCategoryInputType
  ) {
    updateLoanApplicationCategory(
      loanApplicationId: $loanApplicationId
      category: $category
    ) {
      category {
        attitude
        honesty
        availability
        purposeOfLoanCategory
        purposeOfLoan
      }
    }
  }
`;
