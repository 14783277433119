/**
 * Create a page with a search input and a list of results.
 */

import React, {useEffect, useState} from 'react';
import {navigate} from 'gatsby';
import {useLocation} from '@reach/router';
import {HomePageLayout} from '../../components/HomePageLayout';
import {TitleBar} from '../../components/TitleBar';
import {ItemsTable} from '../../components/ItemsTable';
import {LoanApplicationObject} from '../../gql/graphql';
import {formatMoney} from '../../utils/format-money';
import {DEFAULT_CURRENCY} from '../../constants/constants';
import {SearchActionBtn} from './SearchAction';
import {useSearchLoanApplications} from '../../hooks/useSearchLoanApplications';
import {showToast} from '../../components/Toast';

type Props = {
  path: string;
};

const headers = [
  {name: 'ID'},
  {name: 'Business Name'},
  {name: 'Client Name'},
  {name: 'Mobile Money Number'},
  {name: 'Phone Number'},
  {name: 'Current Stage'},
  {name: 'Send To'},
];

export const Search = ({}: Props) => {
  const [query, setQuery] = useState('');
  const [searchOption, setSearchOption] = useState('phone');
  const location = useLocation();
  const {search, loading, error, loanApplications} =
    useSearchLoanApplications();

  const submit = async () => {
    if (searchOption !== '' && query !== '') {
      const results = await search({
        variables: {
          searchOption,
          searchText: query,
        },
      });
      console.log('results', results);
    } else {
      showToast({
        open: true,
        positive: false,
        message: 'Please enter a valid search option and query',
      });
    }
  };

  useEffect(() => {
    if (error) {
      showToast({
        open: true,
        positive: false,
        message: `Error: ${error.message}`,
      });
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const option = params.get('option') || 'phone';
    const q = params.get('q') || '';
    setQuery(q);
    setSearchOption(option);
  }, []);

  useEffect(() => {
    window.history.pushState({}, '', `?option=${searchOption}&&q=${query}`);
  }, [searchOption, query]);

  const getTableItems = (_resubmitted?: LoanApplicationObject[]) => {
    if (!_resubmitted || !Array.isArray(_resubmitted)) return [];
    return _resubmitted.map(application => {
      return {
        id: application?.pk,
        businessName: (
          <div
            className="px-2 text-start text-blue-500 cursor-pointer"
            onClick={() => {
              navigate(`${application.id}/detail`);
            }}>
            {application?.business?.name}
          </div>
        ),
        clientName: application?.business?.borrower?.name,
        mobileMoneyNumber: application?.business?.borrower?.mobileMoneyNumber,
        phone: application?.business?.borrower?.phoneNumber,
        currentStage: application?.stage?.stage,
        action: <SearchActionBtn id={application.id} />,
      };
    });
  };

  return (
    <HomePageLayout loading={loading}>
      <TitleBar title="Search" />
      <div className="px-2 py-4">
        <p className="p-2">
          You can search by partial names and numbers but please try to be
          specific to limit the returned results
        </p>
        <select
          className="p-2 mx-2"
          defaultValue={searchOption}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setSearchOption(e.target.value)
          }>
          <option value="phone">Phone Number</option>
          <option value="borrower">Client Name</option>
          <option value="business">Business Name</option>
        </select>
        <input
          className="border-2 border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
          type="text"
          value={query}
          onChange={e => setQuery(e.target.value)}
        />
        <button
          className="px-4 py-2 border border-blue-400 bg-blue-400 shadow-sm mx-2
          rounded-md text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          onClick={submit}>
          Search
        </button>
        <div className="px-2 bg-white my-4">
          <ItemsTable
            headers={headers}
            items={getTableItems(loanApplications)}
          />
          {(!loanApplications || loanApplications?.length == 0) && (
            <p className="p-8 text-center text-gray-600">
              No search results. Make sure you select the correct option for
              what
              <br />
              <span>
                you are searching, for example, if you are searching the
                business name,
              </span>
              <br />
              <span>
                please select the business name option from the drop down.
              </span>
            </p>
          )}
        </div>
      </div>
    </HomePageLayout>
  );
};
