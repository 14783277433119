import {ApolloError, useQuery} from '@apollo/client';
import {PORTFOLIO_DATA} from '../queries/portfolio';

export type PortfolioType = {
  id: number;
  createdAtUtc: string;
  disbursementTarget: number;
  acquisitionTarget: number;
  retentionTarget: number;
  collectionTarget: number;
  active: boolean;
  month: number;
  year: number;
  pk: number;
  newClientsTotal: number;
  acquisitionRate: number;
  differenceFromAcquisitionTarget: number;
  activeClients: number;
  repaymentRate: number;
  differenceFromDisbursementTarget: number;
  disbursementRate: number;
  dueLastMonth: number;
  collectionTargetLastMonth: number;
  collectionRateLastMonth: number;
  totalDisbursed: number;
  outStandingPortfolio: number;
  collectedLastMonth: number;
};

type UsePortfolioType = {
  loading: boolean;
  error?: ApolloError;
  portfolio?: PortfolioType;
};

export const usePortfolio = (): UsePortfolioType => {
  const {loading, error, data} = useQuery(PORTFOLIO_DATA);
  return {
    loading,
    error,
    portfolio: data?.portfolio,
  };
};
