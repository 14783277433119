import React, {useEffect, useState} from 'react';
import AccessDenied from '../../components/AccessDenied';
import {CreatePortfolioDialog} from '../../components/CreatePortfolioDialog';
// import BarChart from '../../components/BarChart';
import {HomePageLayout} from '../../components/HomePageLayout';
import {TitleBar} from '../../components/TitleBar';
import {invalidInput} from '../../components/Toast';
import {DEFAULT_CURRENCY} from '../../constants/constants';
import {useCreatePortfolio} from '../../hooks/useCreatePortfolio';
import {useHasPagePermission} from '../../hooks/useHasPermission';
import {usePortfolio} from '../../hooks/usePorfolio';
import {useSSE} from '../../hooks/useServerSideMesages';
import {formatMoney} from '../../utils/format-money';
import {AcquisitionCards} from './components/AcquisitionCards';
import {ActiveLoanCard} from './components/ActiveLoanCard';
import {CollectionsBlock} from './components/CollectionsBlock';
import {TargetCard} from './components/TargeCard';

type Props = {
  path?: string;
};

export const Dashboard = ({}: Props) => {
  const {loading, error, portfolio} = usePortfolio();
  const [open, setOpen] = useState<boolean>(false);
  const [savingPortfolio, setSavingPortfolio] = useState(false);
  const {message} = useSSE();

  const hasPerm = useHasPagePermission();

  useEffect(() => {
    // console.log('server message', message);
  }, [message]);

  const {
    loading: creatingPortfolio,
    error: savingPortfolioError,
    createPortfolio,
  } = useCreatePortfolio();

  useEffect(() => {
    if (error) {
      invalidInput(
        `An error occurred when loading dashboard data: ${error.message}`,
      );
    }
    if (savingPortfolioError) {
      invalidInput(
        `An error occurred when saving portfolio targets: ${savingPortfolioError.message}`,
      );
    }
  }, [error, savingPortfolioError]);

  if (!hasPerm('/app/dashboard')) return <AccessDenied />;

  return (
    <HomePageLayout loading={loading || savingPortfolio || creatingPortfolio}>
      {open && (
        <CreatePortfolioDialog
          createPortfolio={createPortfolio}
          open={open}
          setLoading={setSavingPortfolio}
          onClose={() => setOpen(false)}
        />
      )}
      <div className="flex flex-col">
        <div
          id="title-container"
          className="flex flex-row items-center bg-white shadow-md">
          <TitleBar title="Dashboard" />
          <button
            onClick={() => setOpen(true)}
            className="px-8 py-2 m-2 border border-blue-600 hover:bg-blue-600 
            hover:text-white text-blue-600 rounded-lg">
            SET TARGETS
          </button>
          <div className="flex flex-row pr-12 cursor-pointer">
            <div className="px-4 py-2 bg-blue-800 text-gray-200 rounded-l-lg">
              Monthly
            </div>
            <div className="px-4 py-2 border border-blue-800 text-blue-800 rounded-r-lg">
              Weekly
            </div>
          </div>
        </div>
        <div className="px-8 pb-12">
          <div className="bg-white p-4 flex flex-col rounded-lg mt-8 shadow-md">
            <div id="target-cards-container" className="flex flex-wrap">
              <TargetCard
                title="Disbursement Target"
                description={formatMoney(
                  portfolio?.disbursementTarget,
                  DEFAULT_CURRENCY,
                )}
              />
              <TargetCard
                title="Total Disbursed"
                description={formatMoney(
                  portfolio?.totalDisbursed,
                  DEFAULT_CURRENCY,
                )}
              />
              <TargetCard
                title="% of the target"
                description={`${portfolio?.disbursementRate ?? '-'}%`}
              />
              <TargetCard
                title="Difference from target"
                description={formatMoney(
                  portfolio?.differenceFromDisbursementTarget,
                  DEFAULT_CURRENCY,
                )}
              />
              <TargetCard
                title="Repayment Rate"
                description={`${portfolio?.repaymentRate ?? '-'}%`}
              />
            </div>
            <div id="acquisition-container" className="flex flex-col mt-8">
              <div className="text-black text-base font-bold font-medium px-2">
                Acquisition
              </div>
              <div className="flex flex-row mt-4">
                <div className="flex flex-wrap grow">
                  <AcquisitionCards
                    title={portfolio?.acquisitionTarget}
                    description="Target"
                  />
                  <AcquisitionCards
                    title={portfolio?.newClientsTotal}
                    description="New Clients Acquired"
                  />
                  <AcquisitionCards
                    title={`${portfolio?.acquisitionRate ?? '-'}%`}
                    description="% rate of the target"
                  />
                  <AcquisitionCards
                    title={portfolio?.differenceFromAcquisitionTarget}
                    description="Difference from target"
                  />
                </div>
                <div className="grid grid-cols-1 gap-2">
                  <ActiveLoanCard
                    title="Active loans"
                    description={portfolio?.activeClients}
                    containerClass="bg-red-200"
                    circleClass="border-red-300 rounded-full border-8 h-8 w-8 ml-16"
                  />
                  <ActiveLoanCard
                    title="Outstanding Portfolio"
                    description={formatMoney(
                      portfolio?.outStandingPortfolio,
                      DEFAULT_CURRENCY,
                    )}
                    containerClass="bg-yellow-100"
                    circleClass="border-orange-300 rounded-full border-8 h-8 w-8 ml-16"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-3 mt-8">
            <CollectionsBlock
              title="Collections"
              data={[
                {
                  title: formatMoney(portfolio?.dueLastMonth, DEFAULT_CURRENCY),
                  description: 'Due Last Month',
                },
                {
                  title: formatMoney(
                    portfolio?.collectionTargetLastMonth,
                    DEFAULT_CURRENCY,
                  ),
                  description: 'Target Last Month',
                },
                {
                  title: `${portfolio?.collectionRateLastMonth ?? '-'}%`,
                  description: 'Rate Last Month',
                },
                {
                  title: formatMoney(
                    portfolio?.collectedLastMonth,
                    DEFAULT_CURRENCY,
                  ),
                  description: 'Paid Last Month',
                },
              ]}
            />
            {/* <CollectionsBlock
              title="Retention Target"
              data={[
                {title: '732', description: 'Due Last Month'},
                {title: '200', description: 'Target Last Month'},
                {title: '843', description: 'Rate Last Month'},
                {title: '432', description: 'Paid Last Month'},
              ]}
            />
            <div className="flex items-center justify-center rounded-xl bg-white p-4 shadow-md">
              <BarChart />
            </div> */}
          </div>
        </div>
      </div>
    </HomePageLayout>
  );
};
