import {gql} from '@apollo/client';

export const CREATE_CONTACT_ATTEMPT = gql`
  mutation CreateContactAttemptMutation(
    $contactAttempt: ContactAttemptInputType!
  ) {
    createContactAttempt(contactAttempt: $contactAttempt) {
      contactAttempt {
        id
        createdAtUtc
        channel
        staff {
          id
          firstName
          lastName
        }
        contactedSuccessfully
      }
    }
  }
`;
