import React, {ReactNode, useEffect, useMemo, useState} from 'react';
import {navigate} from 'gatsby';
import _ from 'lodash';
import {HomePageLayout} from '../../components/HomePageLayout';
import {ItemsTable} from '../../components/ItemsTable';
import {Search} from '../../components/Search';
import {Tabs} from '../../components/Tabs';
import {TitleBar} from '../../components/TitleBar';
import {DEFAULT_CURRENCY, ITEMS_PER_PAGE} from '../../constants/constants';
import {useLoanApplications} from '../../hooks/useLoanApplications';
import {formatMoney} from '../../utils/format-money';
import {
  LoanApplicationObject,
  LoanApplicationStatusesType,
} from '../../gql/graphql';
import {useRefetchOnLocationChange} from '../../hooks/useRefetchOnLocationChange';
import {invalidInput} from '../../components/Toast';
import usePagination from '../../hooks/usePagination';
import {LoanApplicationType} from '../../hooks/useLoanApplication';
import Pagination from '../../components/Pagination';

type Props = {
  path?: string;
};

const pendingHeaders = [
  {name: 'ID'},
  {name: 'Business Name'},
  {name: 'Client Name'},
  {name: 'Date Entered DD'},
  {name: 'Amount requested'},
  {name: 'Quoted principle'},
  {name: 'Loan History'},
  {name: 'DCO'},
];

const PENDING = 'Pending';

export const DueDiligence = ({}: Props) => {
  const [activeTab, setActiveTab] = useState(PENDING);

  const [headers, setHeaders] = useState(pendingHeaders);

  const [items, setItems] = useState<{[x: string]: string | ReactNode}[]>([]);

  const [prevApplications, setPrevApplications] = useState<
    LoanApplicationType[] | undefined
  >([]);

  const {
    loanApplications,
    error,
    loading,
    refetch,
    totalCount,
    pageInfo,
    fetchMore,
  } = useLoanApplications([LoanApplicationStatusesType.DueDiligence]);

  const {currentPage, totalPages, goToNextPage, goToPreviousPage} =
    usePagination(ITEMS_PER_PAGE, totalCount, activeTab);

  useRefetchOnLocationChange(refetch);

  useEffect(() => {
    if (error) {
      invalidInput(error.message);
    }
  }, [error]);

  const getItems = (_loanApplications?: LoanApplicationObject[]) => {
    console.log('loanApplications: ', _loanApplications);
    if (!_loanApplications || !Array.isArray(_loanApplications)) return [];
    return _loanApplications.map(application => ({
      id: application?.pk,
      businessName: (
        <div
          className="px-2 text-start text-blue-500 cursor-pointer"
          onClick={() => {
            navigate(`${application.id}/detail`);
          }}>
          {application?.business?.name}
        </div>
      ),
      clientName: application.business?.borrower?.name,
      dateEnteredDD: new Date(
        application?.stage?.createdAtUtc,
      ).toLocaleDateString(),
      amountRequested: formatMoney(
        application?.loanQuote?.amount,
        DEFAULT_CURRENCY,
      ),
      principal: formatMoney(
        application?.loanQuote?.principal,
        DEFAULT_CURRENCY,
      ),
      loanHistory: application?.business?.loans?.length ?? '-',
      dco: application?.dcoOfficer?.fullName ?? '-',
    }));
  };

  useEffect(() => {
    if (!loanApplications || !Array.isArray(loanApplications)) return;
    if (!_.isEqual(prevApplications, loanApplications)) {
      setItems(getItems(loanApplications));
      setPrevApplications(loanApplications);
    }
  }, [loanApplications]);

  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    switch (activeTab) {
      case PENDING:
        if (!loanApplications || !Array.isArray(loanApplications)) return;
        setItems(
          getItems(
            loanApplications?.filter(
              obj =>
                new RegExp(searchText, 'i').test(obj.business?.name ?? '') ||
                new RegExp(searchText, 'i').test(
                  obj.business?.borrower?.name ?? '',
                ) ||
                new RegExp(searchText, 'i').test(
                  new Date(obj?.stage?.createdAtUtc).toLocaleDateString() ?? '',
                ),
            ),
          ),
        );
        return;
      default:
        return;
    }
  }, [searchText]);

  return (
    <HomePageLayout loading={loading}>
      <div className="flex flex-col grow w-full h-full">
        <TitleBar title="Due Diligence" />
        <div className="flex flex-col px-4 py-2 bg-white m-4 rounded-lg shadow-md">
          <Search
            searchText={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
          <div className="w-4/12">
            <Tabs
              tabs={[
                {title: PENDING, active: activeTab === PENDING},
                // {title: REASSESSMENT, active: activeTab === REASSESSMENT},
              ]}
              onClick={title => {
                setHeaders(pendingHeaders);
                setItems(getItems(loanApplications));
                setActiveTab(title);
              }}
            />
          </div>
          <ItemsTable headers={headers} items={items} />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalCount}
            goToNextPage={(fetchMore, endCursor) =>
              goToNextPage(fetchMore, endCursor)
            }
            goToPreviousPage={(fetchMore, startCursor) =>
              goToPreviousPage(fetchMore, startCursor)
            }
            pageInfo={pageInfo}
            fetchMore={fetchMore}
          />
        </div>
      </div>
    </HomePageLayout>
  );
};
