import {MutationFunctionOptions} from '@apollo/client';
import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import {permissions} from '../constants/constants';
import {RolesType} from '../hooks/useRoles';
import {Checkbox} from './checkbox';
import {Dialog} from './Dialog';
import {SingleInput} from './SingleInput';

type Props = {
  open: boolean;
  onClose: () => void;
  setLoading: (loading: boolean) => void;
  createRole: (options: MutationFunctionOptions) => void;
  role?: RolesType;
};

type FormDataType = {
  role: string;
  [x: string]: any;
};

type FormDataErrorType = {
  role?: string;
  [x: string]: any;
};

type RolesInputProps = {
  formData: FormDataType;
  setFormData: (formData: FormDataType) => void;
};

const RolesInput = ({formData, setFormData}: RolesInputProps) => {
  return (
    <>
      <span className="mt-8 text-gray-600">Select Role Permissions</span>
      <div className="grid grid-cols-3 gap-4 w-full mt-4">
        {Object.values(permissions).map(permission => {
          return (
            <Checkbox
              title={permission.name}
              checked={formData[`permission_${permission.name}`] ?? false}
              setChecked={checked =>
                setFormData({
                  ...formData,
                  [`permission_${permission.name}`]: checked,
                })
              }
              key={permission.name}
            />
          );
        })}
      </div>
    </>
  );
};

export const CreateRoleDialog = ({
  open,
  onClose,
  setLoading,
  createRole,
  role,
}: Props) => {
  const getInitialFormData = () => {
    if (role) {
      const permissions = role.permissions?.reduce((prev, current) => {
        prev[`permission_${current}`] = true;
        return prev;
      }, {} as any);
      return {role: role.name, ...permissions};
    } else {
      return {role: ''};
    }
  };

  const [formData, setFormData] = useState<FormDataType>({role: ''});
  const [formDataErrors, setDataFormErrors] = useState<FormDataErrorType>({});

  const onChange = (e: ChangeEvent<HTMLInputElement>, name: string) => {
    setFormData({...formData, [name]: e.target.value});
    setDataFormErrors({...formDataErrors, [name]: undefined});
  };

  useEffect(() => {
    if (role) {
      setFormData({...getInitialFormData()});
    }
  }, [role]);

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);

    if (formData.role.length === 0) {
      setDataFormErrors({
        ...formDataErrors,
        role: 'Please enter a role name',
      });
      return;
    }

    const keys = Object.keys(formData);

    const rolePermissions = keys.reduce((prev, current_value) => {
      if (current_value.startsWith('permission')) {
        const role_name = current_value.slice(11);
        if (formData[current_value]) {
          prev.push(role_name);
        }
      }
      return prev;
    }, [] as any);

    const data = {
      name: formData.role,
      permissions: rolePermissions,
    };

    createRole({variables: data});
    setLoading(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Create Role"
      btnText="Save Role"
      onSubmit={e => onSubmit(e)}
      description="Create a role and add different permissions">
      <div>
        <SingleInput
          title="Role Name"
          value={formData.role}
          onChange={e => onChange(e, 'role')}
        />
        <RolesInput formData={formData} setFormData={setFormData} />
      </div>
    </Dialog>
  );
};
