import {gql} from '@apollo/client';

export const EMAIL_PASSWORD_LOGIN = gql`
  mutation EmailPasswordLogin($username: String!, $password: String!) {
    emailPasswordLogin(password: $password, username: $username) {
      message
      token
      success
    }
  }
`;
