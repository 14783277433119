import React from 'react';
import {
  HideCards,
  LoanApplicationDetail,
} from '../../components/LoanApplicationDetail/LoanApplicationDetail';
import {AllDetailsGroup} from '../../components/LoanApplicationDetail/components/AllDetailsGroup';
import {LoanApplicationStatusesType} from '../../gql/graphql';

type Props = {
  path?: string;
  applicationId: string;
};

const hideCards: HideCards = {
  purposeOfLoan: true,
  adjustLoaAmount: true,
  visitAttempts: true,
};

export const PrescreeningDetail = ({applicationId, path}: Props) => {
  return (
    <LoanApplicationDetail
      applicationId={applicationId}
      path={path}
      hideCards={hideCards}
      stage={LoanApplicationStatusesType.PreScreening}>
      <AllDetailsGroup />
    </LoanApplicationDetail>
  );
};
