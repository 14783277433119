import React, {SyntheticEvent, useEffect, useState} from 'react';
import {CheckCircleIcon, XIcon} from '@heroicons/react/outline';
import {PencilAltIcon} from '@heroicons/react/solid';
import {invalidInput, validInput} from '../../Toast';
import {useLoanApplicationDetail} from '../LoanApplicationDetail';
import {useHasPermissions} from '../../../hooks/useHasPermission';
import {permissions as p} from '../../../constants/constants';

type Props = {
  title?: string;
  loginNumber?: boolean;
};

export const MobileNumber = ({title, loginNumber}: Props) => {
  const {loanApplication, updateBorrower} = useLoanApplicationDetail();

  const mobileNumber = loginNumber
    ? loanApplication?.business?.borrower?.phoneNumber
    : loanApplication?.business?.borrower?.mobileMoneyNumber;
  const verified = loanApplication?.business?.borrower?.phoneNumberVerified;

  const [update, setUpdate] = useState<boolean>(false);
  const [phone, setPhone] = useState<string | undefined | null>(mobileNumber);
  const {hasPermissions} = useHasPermissions();

  const canEditNumber = hasPermissions(p.PERMISSION_UPDATE_BORROWER_DATA.name);

  useEffect(() => {
    if (!phone && mobileNumber) {
      setPhone(mobileNumber);
    }
  }, [mobileNumber]);

  const handleUpdate = async (e: SyntheticEvent) => {
    if (!canEditNumber) {
      invalidInput('You do not have permission to edit the phone number');
      return;
    }
    e.preventDefault();
    if (phone && !/^(?:2560|\+256|256|0|)([237]\d{8})$/g.test(phone)) {
      invalidInput('Phone number is incorrect');
      return;
    }
    if (phone === mobileNumber) {
      invalidInput('Please update phone number before you can save it.');
      return;
    }
    try {
      await updateBorrower({
        variables: {
          loanApplicationId: loanApplication?.id,
          borrower: {mobileMoneyNumber: phone},
        },
      });
      validInput('Mobile money updated successfully');
      setUpdate(false);
    } catch (e: any) {
      invalidInput(e.message);
    }
  };

  return (
    <div className="flex flex-col  w-full px-12 py-4">
      <div className="text-blue-600 font-medium text-lg my-2">
        {title ?? 'Mobile Money Number'}
      </div>
      <div className="grid grid-cols-2 items-center">
        <div className="flex flex-col">
          <div className="font-medium text-black">Number</div>
          <div className="flex flex-row items-center">
            {update ? (
              <form>
                <input
                  id="update-mobile-number"
                  onChange={e => setPhone(e.target.value)}
                  value={phone ?? ''}
                  className="border border-gray-300 rounded-md px-4 py-2 grow"
                />
                <button
                  className="text-align-end rounded-md px-8 py-2 bg-blue-600 text-gray-200 mt-8 mx-2"
                  onClick={async e => {
                    await handleUpdate(e);
                  }}>
                  Save
                </button>
                <span
                  className="underline cursor-pointer"
                  onClick={() => setUpdate(false)}>
                  Cancel
                </span>
              </form>
            ) : (
              <>
                <div className="text-blue-600 text-lg mt-2">{mobileNumber}</div>
                {canEditNumber && !loginNumber && (
                  <div
                    className="h-9 w-9 flex items-center justify-center mx-4"
                    onClick={() => setUpdate(true)}>
                    <PencilAltIcon className="text-gray-500 w-full h-full px-1" />
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        {!loginNumber && (
          <div className="flex flex-col">
            <div className="font-medium text-black">Verified</div>
            <div className="h-10 w-10 flex items-center justify-center mt-2">
              {verified ? (
                <CheckCircleIcon className="text-green-600 w-full h-full" />
              ) : (
                <XIcon className="text-red-600 w-full h-full" />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
