import React from 'react';
import {permissions} from '../../../constants/constants';
import {LoanApplicationObject} from '../../../gql/graphql';
import {useHasPermissions} from '../../../hooks/useHasPermission';
import {MobileMoneyTransactions} from './MobileMoneyTransactions';

type Props = {
  loanApplication: LoanApplicationObject;
};

export const MobileMoneyTransactionsGroup = ({loanApplication}: Props) => {
  const {hasPermissions} = useHasPermissions();
  return (
    <div className="mt-8 w-full grid grid-cols-2 gap-8">
      <MobileMoneyTransactions
        title="Mobile Money Transactions"
        transactions={[
          {
            title: 'Biggest amount received last week',
            amount:
              loanApplication?.business?.transactions
                ?.biggestMobileMoneyReceived,
          },
          {
            title: 'Smallest amount received last week',
            amount:
              loanApplication?.business?.transactions
                ?.smallestMobileMoneyReceived,
          },
          {
            title: 'Biggest amount sent last week',
            amount:
              loanApplication?.business?.transactions?.biggestMobileMoneySent,
          },
          {
            title: 'Smallest amount sent last week',
            amount:
              loanApplication?.business?.transactions?.smallestMobileMoneySent,
          },
        ]}
      />
      <MobileMoneyTransactions
        title="Business Sales"
        editable={hasPermissions(permissions.PERMISSION_UPDATE_BUSINESS.name)}
        transactions={[
          {
            title: 'Total Sales Last Week',
            amount: loanApplication?.business?.transactions?.salesLastWeek,
          },
          {
            title: 'Average sales in day',
            amount: loanApplication?.business?.transactions?.averageSalesInADay,
          },
          {
            title: 'Possible Weekly Payment',
            amount:
              loanApplication?.business?.transactions?.possibleWeeklyPayment,
          },
        ]}
      />
    </div>
  );
};
