import React from 'react';
import {VerificationLink} from './VerificationLink';

export const VerificationLinks = ({}) => {
  return (
    <div className="flex flex-col bg-gray-50 rounded-lg p-4 w-full">
      <div className="text-blue-600 font-medium text-lg my-2">
        Due Diligence
      </div>
      <VerificationLink
        title="KCCA portal link"
        link="https://ecitie2.kcca.go.ug/portal/payment/register-payment"
      />
      <VerificationLink
        title="URSB portal link"
        link="https://ursb.go.ug/brs/pro/bnr/searchname"
      />
      <VerificationLink
        title="NIRA/NIN portal link"
        link="https://nira.go.ug/home"
      />
    </div>
  );
};
