import React from 'react';
import {DetailTitleBar} from './DetailTitleBar';
import {LoanTerms} from './LoanTerms';
import {AccountDetails} from './AccountDetails';
import {Comments} from './Comments';
import {DetailInLineTitle} from './DetailInLineTitle';
import {MobileMoneyTransactionsGroup} from './MobileMoneyTransactionsGroup';
import {NextOfKinGroup} from './NextOfKinGroup';
import {PurposeOfLoanGroup} from './PurposeOfLoanGroup';
import {AdjustLoanGroup} from './AdjustLoanGroup';
import {DueDiligenceLinksGroup} from './DueDiligenceLinksGroup';
import {ActionButtonsGroup} from './ActionButtonsGroup';
import {useLoanApplicationDetail} from '../LoanApplicationDetail';
import {ItemsTable} from '../../ItemsTable';

type Props = {
  path?: string;
  applicationId: string;
};

export const AllDetailsGroup = () => {
  const {
    loanApplication,
    dialog,
    setDialog,
    setLoanDismissalReason,
    updateStage,
    photoResubmit,
    setLoanApplicationCategory,
    loanApplicationCategory,
    handleSubmitLoanApplicationCategory,
    newQuote,
    setNewQuote,
    handleSaveQuote,
    getVisitAttempts,
    hideCards,
    stage,
    requestOtp,
    getLoans,
    getProfileMatches,
    getLoanPayments,
  } = useLoanApplicationDetail();
  const {headers: loanHeaders, items: loanItems} = getLoans();
  const {headers: nameMatchesHeaders, items: profileMatches} =
    getProfileMatches();
  const {headers: loanPaymentsHeaders, items: loanPayments} = getLoanPayments();
  return (
    <>
      <div className="flex flex-col bg-white rounded-xl mt-4 p-4 w-full shadow-md">
        {!hideCards?.titleBar && (
          <DetailTitleBar
            userName={loanApplication?.business?.borrower?.name}
            district={loanApplication?.business?.district}
            businessName={loanApplication?.business?.name}
            businessId={loanApplication?.business?.pk}
          />
        )}
        {!hideCards?.loanTerms && loanApplication?.loanQuote && (
          <div className="mt-8 w-full">
            <LoanTerms
              quote={loanApplication?.loanQuote}
              pk={loanApplication?.pk}
            />
          </div>
        )}
        <div className="mt-8 w-full border border-gray-300 rounded-lg p-4">
          <DetailInLineTitle title="Loan History" />
          <ItemsTable
            headers={loanHeaders}
            items={loanItems}
            displayTitle="Loan History"
          />
        </div>
        <div className="mt-8 w-full border border-gray-300 rounded-lg p-4">
          <DetailInLineTitle title="Business / Profile Name Matches" />
          <ItemsTable
            headers={nameMatchesHeaders}
            items={profileMatches}
            displayTitle="Business / Profile Name Matches"
          />
        </div>
        <div className="mt-8 w-full border border-gray-300 rounded-lg p-4">
          <DetailInLineTitle title="Loan Payments" />
          <ItemsTable
            headers={loanPaymentsHeaders}
            items={loanPayments}
            displayTitle="Loan Payments"
          />
        </div>
        {!hideCards?.mobileMoneyTransactions && (
          <MobileMoneyTransactionsGroup loanApplication={loanApplication} />
        )}
        {!hideCards?.nextOfKin && (
          <NextOfKinGroup loanApplication={loanApplication} />
        )}
        {!hideCards?.AccountDetails && (
          <div className="mt-8 w-full grid grid-cols-4 gap-8">
            <AccountDetails loanApplication={loanApplication} />
          </div>
        )}
        {!hideCards?.purposeOfLoan && (
          <PurposeOfLoanGroup
            loanApplicationCategory={loanApplicationCategory}
            setLoanApplicationCategory={setLoanApplicationCategory}
            handleSubmitLoanApplicationCategory={
              handleSubmitLoanApplicationCategory
            }
          />
        )}
        {!hideCards?.adjustLoaAmount && (
          <AdjustLoanGroup
            loanApplication={loanApplication}
            newQuote={newQuote}
            setNewQuote={setNewQuote}
            handleSaveQuote={handleSaveQuote}
          />
        )}
      </div>
      <div className="flex flex-col bg-white rounded-xl mt-8 p-4 w-full shadow-md">
        {!hideCards?.dueDiligenceLinks && <DueDiligenceLinksGroup />}
        {!hideCards?.visitAttempts && (
          <div className="w-full px-8 py-4 mt-4">
            <Comments
              title="Visit Attempts"
              headers={['Business Existence', 'Date', 'Staff']}
              data={getVisitAttempts()}
              addComment={() =>
                setDialog({
                  ...dialog,
                  visitAttempts: {
                    ...dialog?.visitAttempts,
                    open: true,
                    data: loanApplication?.id,
                  },
                })
              }
            />
          </div>
        )}
        {!hideCards?.actionButtons && (
          <ActionButtonsGroup
            setLoanDismissalReason={setLoanDismissalReason}
            updateStage={updateStage}
            photoResubmit={photoResubmit}
            stage={stage}
            setDialog={setDialog}
            dialog={dialog}
            requestOtp={requestOtp}
            loanApplication={loanApplication}
          />
        )}
      </div>
    </>
  );
};
