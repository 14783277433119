import {Maybe} from '../gql/graphql';

export const snakeToSentenceCase = (
  text?: Maybe<string>,
): Maybe<string> | string | undefined => {
  if (!text) return text;
  return text
    .split('_')
    .map(word => toTitleCase(word))
    .join(' ');
};

export const toTitleCase = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase();
};
