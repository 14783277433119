import React, {SyntheticEvent} from 'react';
import {Maybe} from '../../../gql/graphql';
import {AddComment} from './AddComment';

type CommentsProps = {
  headers: string[];
  data: {[x: string]: string | JSX.Element | Maybe<string> | undefined}[];
  title: string;
  addComment: (e: SyntheticEvent) => void;
};

export const Comments = ({headers, data, title, addComment}: CommentsProps) => {
  return (
    <div className="flex flex-col border border-gray-200 rounded-lg w-full">
      <div className="text-blue-600 font-medium text-lg my-2 px-4">{title}</div>
      <table className="">
        <thead>
          <tr>
            {headers.map(header => (
              <th key={header} className="text-black text-left py-2 px-4">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr className="" key={`comment-${title}-${index}`}>
                {Object.keys(item).map(key => {
                  return (
                    <td
                      key={`comment-${title}-${index}-${key}`}
                      className="text-left border-y-2 border-gray-200 py-2 px-4">
                      {item[key]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <AddComment onClick={addComment} />
    </div>
  );
};
