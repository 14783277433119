import React from 'react';
import {ExclamationIcon} from '@heroicons/react/solid';

function AccessDenied() {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <ExclamationIcon className="text-red-500 h-40 w-40"></ExclamationIcon>
      <h1 className="text-center text-red-600 text-7xl font-medium m-4">
        Access Denied
      </h1>
      <p className="text-center text-2xl font-medium m-4">
        You do not have permission to view this page.
      </p>
      <h6 className="text-red-500 underline">error code:403 forbidden</h6>
    </div>
  );
}

export default AccessDenied;
