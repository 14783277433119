import {makeVar} from '@apollo/client';
import {useState, useEffect} from 'react';

export const fetchingMore = makeVar<boolean>(false);

const usePagination = (
  itemsPerPage: number,
  totalItems: number,
  activeTab?: string,
) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(
    Math.ceil(totalItems / itemsPerPage || 1),
  );

  useEffect(() => {
    setTotalPages(Math.ceil(totalItems / itemsPerPage || 1));
  }, [totalItems, itemsPerPage]);

  const goToNextPage = async (
    fetchMore: any,
    endCursor: string,
    objectType: string = 'loanApplications',
  ) => {
    fetchingMore(true);
    await fetchMore({
      variables: {
        first: itemsPerPage,
        after: endCursor,
        before: null,
        last: null,
      },
      updateQuery: (
        previousResult: any,
        {fetchMoreResult}: {fetchMoreResult: any},
      ) => {
        if (!fetchMoreResult) return previousResult;
        return {
          [objectType]: {
            __typename: previousResult[objectType].__typename,
            edges: fetchMoreResult[objectType].edges,
            totalCount: fetchMoreResult[objectType].totalCount,
            pageInfo: fetchMoreResult[objectType].pageInfo,
          },
        };
      },
    });
    fetchingMore(false);
    setCurrentPage(prevPage => prevPage + 1);
  };

  const goToPreviousPage = async (
    fetchMore: any,
    startCursor: string,
    objectType: string = 'loanApplications',
  ) => {
    fetchingMore(true);
    await fetchMore({
      variables: {
        last: itemsPerPage,
        before: startCursor,
        after: null,
        first: null,
      },
      updateQuery: (
        previousResult: any,
        {fetchMoreResult}: {fetchMoreResult: any},
      ) => {
        if (!fetchMoreResult) return previousResult;

        return {
          [objectType]: {
            __typename: previousResult[objectType].__typename,
            edges: fetchMoreResult[objectType].edges,
            totalCount: fetchMoreResult[objectType].totalCount,
            pageInfo: fetchMoreResult[objectType].pageInfo,
          },
        };
      },
    });
    fetchingMore(false);
    setCurrentPage(prevPage => prevPage - 1);
  };

  useEffect(() => {
    if (activeTab) setCurrentPage(1);
  }, [activeTab]);

  return {
    currentPage,
    totalPages,
    goToNextPage,
    goToPreviousPage,
  };
};

export default usePagination;
