import React from 'react';

export type Header = {
  name: string | React.ReactNode;
};

type Item = {
  [x: string]: string | React.ReactNode;
};
type Props = {
  headers: Header[];
  items: Item[];
  displayTitle?: string;
};

export const ItemsTable = ({headers, items, displayTitle}: Props) => {
  const _displayTitle = displayTitle ? displayTitle : 'items';
  return (
    <>
      {items?.length > 0 ? (
        <table className="border-separate border-spacing-y-4 w-full">
          <thead className="">
            <tr className="">
              {headers?.map((header, headerIndex) => (
                <th
                  className="text-black border-b font-medium border-gray-200 py-2 text-start"
                  key={`${header}-${headerIndex}`}>
                  {header.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {items?.map((item: any, parentIndex: number) => (
              <tr className="" key={`parent-${parentIndex}`}>
                {Object.keys(item).map((key: string, index: number) => (
                  <td
                    className="px-2 items-start max-w-xs"
                    key={`${key}-${index}`}>
                    {item[key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-base py-2">
          No <span className="text-lg font-medium">{`${_displayTitle}`} </span>
          to display
        </p>
      )}
    </>
  );
};
