import React from 'react';

type PhotoStateType = {
  label: string;
  state: boolean;
  value: string;
  setActiveState: (name: string) => void;
};

export const PhotoState = ({
  label,
  state,
  value,
  setActiveState,
}: PhotoStateType) => {
  let circleColor = 'transparent';
  let border = 'border border-gray-300';
  let bgColor = 'border border-gray-200';
  let textColor = 'text-gray-400';
  if (state) {
    circleColor = 'bg-blue-600';
    border = '';
    bgColor = 'bg-gray-100';
    textColor = 'text-blue-600';
  }
  return (
    <div
      className={`py-1 px-4 flex flex-row items-center col-span-1 w-full rounded-lg ${bgColor} cursor-pointer`}
      onClick={async e => {
        e.stopPropagation();
        await setActiveState(value);
      }}>
      <div className={`w-4 h-4 ${circleColor} ${border} rounded-full`} />
      <div className={`${textColor} px-2`}>{label}</div>
    </div>
  );
};
