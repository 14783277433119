import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {DELETE_ROLE, DELETE_ROLE_OPTIONS} from '../mutations/deleteRole';

type UseDeleteRoleType = {
  deleteRole: (options: MutationFunctionOptions) => void;
  loading: boolean;
  success: boolean;
  error?: ApolloError;
};

export const useDeleteRole = (): UseDeleteRoleType => {
  const [deleteRole, {data, error, loading}] = useMutation(
    DELETE_ROLE,
    DELETE_ROLE_OPTIONS,
  );
  return {
    deleteRole,
    success: data?.deleteRole?.success,
    error,
    loading,
  };
};
