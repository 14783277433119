import {ApolloError, ApolloQueryResult, useQuery} from '@apollo/client';
import {PHOTO_STATES_TYPE} from '../constants/constants';
import {
  BusinessObject,
  LoanApplicationObject,
  LoanObject,
  LoanQuoteObject,
  Maybe,
  NextOfKinObject,
} from '../gql/graphql';
import {LOAN_APPLICATION_QUERY} from '../queries/loanApplication';

export type NextOfKinType = NextOfKinObject;

export type PhotosType = {
  id?: Maybe<string>;
  url?: Maybe<string>;
  photoType?: Maybe<string>;
  status?: PHOTO_STATES_TYPE;
};

export type LoanQuoteType = LoanQuoteObject;

export type LocationType = {
  speed: number;
  accuracy: number;
  altitude: number;
  bearing: number;
  latitude: number;
  longitude: number;
  provider: string;
  time: string;
};

export type ProfileType = {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  mobileMoneyNumber: string;
  gender: string;
  dateOfBirth: string;
  educationLevel: string;
  nationality: string;
  nin: string;
  nextOfKin: NextOfKinType[];
  photos: PhotosType[];
};

export type LenderType = {
  firstName: string;
  lastName: string;
};

export type CommentsType = {
  id: string;
  comment: string;
  staff: LenderType;
  createdAtUtc: string;
};

export type ContactAttemptsType = {
  id: string;
  channel: string;
  staff: LenderType;
  createdAtUtc: string;
  contactedSuccessfully: boolean;
};

export type VisitAttemptsType = {
  id: string;
  status: string;
  dateVisited: string;
  staff: LenderType;
  createdAtUtc: string;
};

export type TransactionsType = {
  biggestMobileMoneyReceived: number;
  smallestMobileMoneyReceived: number;
  biggestMobileMoneySent: number;
  smallestMobileMoneySent: number;
  salesLastWeek: number;
  averageSalesInADay: number;
  possibleWeeklyPayment: number;
};

export type ResubmissionType = {
  photoType: string;
  id: string;
};

export type BorrowerType = {
  firstName: string;
  lastName: string;
  nin: string;
  dateOfBirth: string;
  phoneNumber: string;
  email: string;
  nationality: string;
  name: string;
  mobileMoneyNumber: string;
  nextOfKin: [NextOfKinType];
  nationalIdName: string;
  gender: string;
  educationLevel: string;
  preferredLanguage: string;
  photos: PhotosType[];
};

export type StageType = {
  id: string;
  createdAtUtc: string;
  loanApplicationId: string;
  endedAtUtc: string;
  stage: string;
  active: boolean;
  comment: string;
  staffId: string;
  staff: ProfileType;
  isActive: boolean;
};

export type LoanStatusType = {
  id: string;
  createdAtUtc: string;
  status: string;
  active: boolean;
  loanId: string;
  pk: number;
};

export type LoanPaymentType = {
  id: string;
  amountPaid: number;
  createdAtUtc: string;
};

export type LoanType = LoanObject;

export type BusinessType = BusinessObject;

export type LoanApplicationType = LoanApplicationObject;
export type RefetchType = (
  variables?: Partial<{id: string}> | undefined,
) => Promise<ApolloQueryResult<any>>;

type UseLoanApplicationsType = {
  loading: boolean;
  error: ApolloError | undefined;
  loanApplication: LoanApplicationType;
  refetch: RefetchType;
};

export const useLoanApplication = (id: string): UseLoanApplicationsType => {
  const {data, loading, error, refetch} = useQuery(LOAN_APPLICATION_QUERY, {
    variables: {id},
  });

  return {
    loanApplication: data?.loanApplication,
    loading,
    error,
    refetch,
  };
};
