import React from 'react';
import {
  HideCards,
  LoanApplicationDetail,
} from '../../components/LoanApplicationDetail/LoanApplicationDetail';
import {AllDetailsGroup} from '../../components/LoanApplicationDetail/components/AllDetailsGroup';
import {LoanApplicationStatusesType} from '../../gql/graphql';

type Props = {
  path?: string;
  applicationId: string;
};

const hideCards: HideCards = {
  actionButtons: false,
  dismissalReasons: true,
};

export const DisbursedLoanDetail = ({applicationId, path}: Props) => {
  return (
    <LoanApplicationDetail
      applicationId={applicationId}
      path={path}
      hideCards={hideCards}
      stage={LoanApplicationStatusesType.Disbursed}>
      <AllDetailsGroup />
    </LoanApplicationDetail>
  );
};
