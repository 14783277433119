import {navigate} from 'gatsby';
import React, {useEffect, useState, useMemo, useCallback} from 'react';
import _ from 'lodash';
import AccessDenied from '../../components/AccessDenied';
import {Checkbox} from '../../components/checkbox';
import {HomePageLayout} from '../../components/HomePageLayout';
import {ItemsTable} from '../../components/ItemsTable';
import {Search} from '../../components/Search';
import {Tabs} from '../../components/Tabs';
import {TitleBar} from '../../components/TitleBar';
import {invalidInput} from '../../components/Toast';
import {DEFAULT_CURRENCY, ITEMS_PER_PAGE} from '../../constants/constants';
import {
  LoanApplicationObject,
  LoanApplicationStatusesType,
} from '../../gql/graphql';
import {useActiveTab} from '../../hooks/useActiveTab';
import {useHasPagePermission} from '../../hooks/useHasPermission';
import {useLoanApplications} from '../../hooks/useLoanApplications';
import {formatMoney} from '../../utils/format-money';
import Pagination from '../../components/Pagination';
import usePagination, {fetchingMore} from '../../hooks/usePagination';
import {useReactiveVar} from '@apollo/client';
import {LoanApplicationType} from '../../hooks/useLoanApplication';

type Props = {
  path?: string;
};

const headers = [
  {name: 'ID'},
  {name: 'Business name'},
  {name: 'Client name'},
  {name: 'Phone Number'},
  {name: 'Principal'},
  {name: 'Interest %'},
  {name: 'Term Days'},
  {name: 'Interest Amount'},
  {name: 'Accepted Terms'},
  {name: 'Officer'},
];

export const Disburse = ({}: Props) => {
  const [activeTab, setActiveTab] = useState('Disbursed');
  const loadingMore = useReactiveVar(fetchingMore);
  const [previousApplications, setPreviousApplications] = useState<
    LoanApplicationType[] | undefined
  >([]);

  const [items, setItems] = useState<any>([]);

  const loanApplicationStatus = useMemo(() => {
    switch (activeTab) {
      case 'Disbursed':
        return LoanApplicationStatusesType.Disbursed;
      case 'Pending':
        return LoanApplicationStatusesType.DisbursementStarted;
      case 'Failed':
        return LoanApplicationStatusesType.DisbursementFailed;
      default:
        return LoanApplicationStatusesType.Disbursed;
    }
  }, [activeTab]);

  const {loanApplications, error, loading, totalCount, pageInfo, fetchMore} =
    useLoanApplications([loanApplicationStatus]);

  const {currentPage, totalPages, goToNextPage, goToPreviousPage} =
    usePagination(ITEMS_PER_PAGE, totalCount, activeTab);

  useEffect(() => {
    if (error) {
      console.error(error);
      invalidInput(
        `An error occurred when loading disbursed loans, ${error.message}`,
      );
    }
  }, [error]);

  useActiveTab(
    {setTab: setActiveTab, defaultTab: 'Disbursed', tab: activeTab},
    title => setActiveTab(title),
  );

  const getItems = useCallback((applications: LoanApplicationObject[]) => {
    console.log('applications', applications);
    return applications.map(application => ({
      id: application?.pk,
      businessName: (
        <div
          className="px-2 text-start text-blue-500 cursor-pointer"
          onClick={() => {
            navigate(`${application.id}/detail`);
          }}>
          {application?.business?.name}
        </div>
      ),
      clientName: application.business?.borrower?.nationalIdName,
      phone: application?.business?.borrower?.phoneNumber,
      principal: formatMoney(
        application?.loanQuote?.principal,
        DEFAULT_CURRENCY,
      ),
      interestPercentage: `${application?.loanQuote?.interest}%`,
      term: application?.loanQuote?.termDays,
      interestAmount:
        formatMoney(application?.loanQuote?.interestAmount, DEFAULT_CURRENCY) ??
        '-',
      acceptedTerms: (
        <Checkbox
          title={application?.acceptedTerms ? 'Yes' : 'No'}
          checked={application?.acceptedTerms}
          setChecked={() => {}}
        />
      ),
      officer: application?.finalReviewer?.fullName ?? '-',
    }));
  }, []);

  const hasPerm = useHasPagePermission();

  useEffect(() => {
    if (!loanApplications || !Array.isArray(loanApplications)) return;
    if (!_.isEqual(loanApplications, previousApplications)) {
      setItems(getItems(loanApplications));
      setPreviousApplications(loanApplications);
    }
  }, [loanApplications]);

  if (!hasPerm('/app/disburse')) return <AccessDenied />;

  return (
    <HomePageLayout loading={loading || loadingMore}>
      <div className="flex flex-col grow w-full h-full">
        <TitleBar title="Disburse" />
        <div className="flex flex-col px-4 py-2 bg-white m-4 rounded-lg shadow-md">
          <Search />
          <div className="w-4/12">
            <Tabs
              tabs={[
                {title: 'Pending', active: activeTab === 'Pending'},
                {title: 'Disbursed', active: activeTab === 'Disbursed'},
                {title: 'Failed', active: activeTab === 'Failed'},
              ]}
              onClick={title => setActiveTab(title)}
            />
          </div>
          <ItemsTable headers={headers} items={items} />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalCount}
            goToNextPage={(fetchMore, endCursor) =>
              goToNextPage(fetchMore, endCursor)
            }
            goToPreviousPage={(fetchMore, startCursor) =>
              goToPreviousPage(fetchMore, startCursor)
            }
            pageInfo={pageInfo}
            fetchMore={fetchMore}
          />
        </div>
      </div>
    </HomePageLayout>
  );
};
