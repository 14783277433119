import React from 'react';
import {Maybe} from '../../../gql/graphql';

type PermissionCardType = {
  permissions: Maybe<Maybe<string>[]> | undefined;
};

export const PermissionCard = ({permissions}: PermissionCardType) => {
  return (
    <div className="flex flex-wrap">
      {permissions?.map(permission => (
        <div
          className="px-2 py-1 bg-gray-100 text-gray-500 my-2 mr-4 rounded-sm"
          key={permission}>
          {permission}
        </div>
      ))}
    </div>
  );
};
