import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {CREATE_ROLE, CREATE_ROLE_OPTIONS} from '../mutations/createRole';
import {RolesType} from './useRoles';

type UseCreateRuleType = {
  loading: boolean;
  role: RolesType;
  error?: ApolloError;
  createRole: (options: MutationFunctionOptions) => void;
};

export const useCreateRole = (): UseCreateRuleType => {
  const [createRole, {data, error, loading}] = useMutation(
    CREATE_ROLE,
    CREATE_ROLE_OPTIONS,
  );

  return {
    role: data?.createRole?.role,
    error,
    loading,
    createRole,
  };
};
