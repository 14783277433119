import React from 'react';
import {HomePageLayout} from '../../components/HomePageLayout';
import {Search} from '../../components/Search';
import {TitleBar} from '../../components/TitleBar';

type Props = {
  path?: string;
};
export const Reports = ({}: Props) => {
  return (
    <HomePageLayout>
      <div className="flex flex-col grow w-full h-full">
        <TitleBar title="Reports" />
        <div className="flex flex-col px-4 py-2 bg-white m-4 rounded-lg shadow-md">
          <Search />
          <div>Coming Soon</div>
        </div>
      </div>
    </HomePageLayout>
  );
};
