import {gql} from '@apollo/client';

export const REQUEST_LENDER_OTP = gql`
  mutation RequestLenderOtpMutation {
    requestLenderOtp {
      success
      message
    }
  }
`;
