import {gql} from '@apollo/client';

export const LOAN_FIELDS = gql`
  fragment LoanFields on LoanObject {
    id
    pk
    loanStatus {
      id
      createdAtUtc
      status
    }
    loanPayments {
      id
      amountPaid
      createdAtUtc
    }
    loanApplication {
      id
      pk
    }
    outStandingBalance
    loanStartTimeUtc
    loanEndTimeUtc
    refundAmount
    createdAtUtc
    loanTermDays
    amount
    totalPaid
    outStandingBalance
    principal
    penaltyAmount
    amountDue
  }
`;

export const DISBURSE_LOAN_MUTATION = gql`
  ${LOAN_FIELDS}
  mutation DisburseLoanMutation($loanApplicationId: ID, $code: String) {
    disburseLoan(loanApplicationId: $loanApplicationId, code: $code) {
      loan {
        ...LoanFields
      }
      success
      message
    }
  }
`;
