import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {AnySrvRecord} from 'dns';
import {LoanApplicationObject} from '../gql/graphql';
import {SEARCH_LOAN_APPLICATIONS} from '../mutations/searchLoanApplications';
import {useEdges} from './useEdges';

type UseSearchLoanApplications = {
  search: (options: MutationFunctionOptions<AnySrvRecord>) => void;
  loading: boolean;
  error: ApolloError | undefined;
  loanApplications: LoanApplicationObject[] | undefined;
};

export const useSearchLoanApplications = (): UseSearchLoanApplications => {
  const [search, {data, error, loading}] = useMutation(
    SEARCH_LOAN_APPLICATIONS,
  );
  const nodes = useEdges(data?.searchLoanApplications?.results);
  return {
    loanApplications: nodes,
    loading,
    error,
    search,
  };
};
