import {gql} from '@apollo/client';

export const CREATE_COMMENT = gql`
  mutation CreateCommentMutation($comment: CommentInputType!) {
    createComment(comment: $comment) {
      comment {
        id
        createdAtUtc
        comment
        staff {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
