import {
  ApolloError,
  gql,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {REQUEST_LENDER_OTP} from '../mutations/requestLenderOtp';

type LenderOtpResponseType = {
  success: string;
  message: string;
  phone: string;
};

type UseRequestLenderOtp = {
  loading: boolean;
  error?: ApolloError;
  requestOtp: (options: MutationFunctionOptions | undefined) => void;
  data?: LenderOtpResponseType;
};

export const useRequestLenderOtp = (): UseRequestLenderOtp => {
  const [requestOtp, {loading, error, data}] = useMutation(REQUEST_LENDER_OTP);
  return {
    loading,
    error,
    requestOtp,
    data: data?.requestLenderOtp,
  };
};
