import {useEffect} from 'react';
import {useLocation} from '@reach/router';

export const useRefetchOnLocationChange = (callback: any) => {
  const location = useLocation();

  useEffect(() => {
    (async () => await callback())();
  }, [location]);
};
