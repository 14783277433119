import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {Maybe} from '../gql/graphql';
import {UPDATE_LOAN_APPLICATION_CATEGORY} from '../mutations/updateLoanApplicationCategory';

export type LoanApplicationCategoryType = {
  id?: Maybe<string>;
  createdAtUtc?: Maybe<string>;
  loanApplicationId?: Maybe<string>;
  purposeOfLoanCategory?: Maybe<string>;
  purposeOfLoan?: Maybe<string>;
  honesty?: Maybe<number>;
  availability?: Maybe<number>;
  attitude?: Maybe<number>;
};
type UseLoanApplicationCategoryUpdate = {
  loading: boolean;
  error?: ApolloError;
  category: LoanApplicationCategoryType;
  updateLoanApplicationCategory: (options: MutationFunctionOptions) => void;
};

export const useUpdateLoanApplicationCategory =
  (): UseLoanApplicationCategoryUpdate => {
    const [updateLoanApplicationCategory, {loading, error, data}] = useMutation(
      UPDATE_LOAN_APPLICATION_CATEGORY,
    );
    return {
      updateLoanApplicationCategory,
      loading,
      error,
      category: data?.updateLoanApplicationCategory?.category,
    };
  };
