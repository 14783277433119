import React from 'react';
import {TargetCardProps} from './TargeCard';

export const ActiveLoanCard = ({
  title,
  description,
  containerClass,
  circleClass,
}: TargetCardProps) => {
  return (
    <div
      className={`flex flex-row items-center rounded-lg px-4 py-0.5 mx-2 px-4 py-2 ${
        containerClass ?? ''
      }`}>
      <div className="flex flex-col grow justify-center">
        <div className="text-xs">{title}</div>
        <div className="text-base font-bold">{description}</div>
      </div>
      <div className={circleClass} />
    </div>
  );
};
