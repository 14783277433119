import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {CollectionsObject} from '../gql/graphql';
import {MAKE_MANUAL_PAYMENT} from '../mutations/makeManualPayment';

type UseManualCollectionType = {
  loading: boolean;
  error?: ApolloError;
  collection: CollectionsObject;
  manualCollection: (options: MutationFunctionOptions) => void;
};

export const useManualCollection = (): UseManualCollectionType => {
  const [manualCollection, {loading, data, error}] =
    useMutation(MAKE_MANUAL_PAYMENT);

  return {
    manualCollection,
    loading,
    error,
    collection: data?.manualCollection?.collection,
  };
};
