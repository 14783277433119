import {gql} from '@apollo/client';

export const UPDATE_BUSINESS_PHOTO_MUTATION = gql`
  mutation UpdateBusinessPhotoMutation($photos: [UpdatePhotoType]) {
    updateBusinessPhoto(photos: $photos) {
      photos {
        id
        url
        status
        photoType
        comment
      }
    }
  }
`;
