import React from 'react';
import {showToast} from '../../../components/Toast';
import {LoanApplicationStatusesType} from '../../../gql/graphql';
import {useUpdateLoanApplicationStage} from '../../../hooks/useUpdateLoanApplicationStage';

type ActionBtnProps = {
  id: string;
};

export const ActionBtn = ({id}: ActionBtnProps) => {
  const {updateLoanApplicationStage} = useUpdateLoanApplicationStage();
  const handleUpdate = async (
    status: LoanApplicationStatusesType,
    message: string,
  ) => {
    try {
      await updateLoanApplicationStage({
        variables: {
          stage: {status},
          loanApplicationId: id,
        },
      });
      showToast({
        open: true,
        message,
        positive: true,
      });
    } catch (e: any) {
      console.error('Error occurred when updating loan application status');
    }
  };

  return (
    <div className="w-full flex flex-row  items-center justify-center p-2">
      <button
        className="border border-blue-600 bg-white rounded-2xl mx-2 px-6 py-1 text-blue-600"
        onClick={async () =>
          await handleUpdate(
            LoanApplicationStatusesType.Declined,
            'Loan has been rejected',
          )
        }>
        Reject
      </button>
      <button
        className="bg-blue-600 mx-2 px-6 rounded-3xl py-1 text-white"
        onClick={async () =>
          await handleUpdate(
            LoanApplicationStatusesType.PreScreening,
            'Loan has been sent back to pre-screening',
          )
        }>
        Review
      </button>
    </div>
  );
};
