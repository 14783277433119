import {MutationFunctionOptions} from '@apollo/client';
import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import {DEFAULT_CURRENCY} from '../constants/constants';
import {BusinessObject} from '../gql/graphql';
import {RolesType} from '../hooks/useRoles';
import {Dialog} from './Dialog';
import {SingleInput} from './SingleInput';
import {showToast} from './Toast';

type Props = {
  open: boolean;
  onClose: () => void;
  setLoading: (loading: boolean) => void;
  updateBusiness: (options: MutationFunctionOptions) => void;
  business?: BusinessObject;
};

type FormDataType = {
  salesLastWeek?: string;
  averageSalesInADay?: string;
  possibleWeeklyPayment?: string;
};

type FormDataErrorType = {
  [x: string]: any;
};

export const UpdateBusinessTransactionsDialog = ({
  open,
  onClose,
  setLoading,
  updateBusiness,
  business,
}: Props) => {
  const getInitialFormData = () => {
    if (business) {
      return {
        salesLastWeek: `${business.transactions?.salesLastWeek ?? ''}`,
        averageSalesInADay: `${
          business.transactions?.averageSalesInADay ?? ''
        }`,
        possibleWeeklyPayment: `${
          business.transactions?.possibleWeeklyPayment ?? ''
        }`,
      };
    } else {
      return {
        salesLastWeek: '',
        averageSalesInADay: '',
        possibleWeeklyPayment: '',
      };
    }
  };

  const [formData, setFormData] = useState<FormDataType>({});
  const [formDataErrors, setDataFormErrors] = useState<FormDataErrorType>({});

  const onChange = (e: ChangeEvent<HTMLInputElement>, name: string) => {
    setFormData({...formData, [name]: e.target.value});
    setDataFormErrors({...formDataErrors, [name]: undefined});
  };

  useEffect(() => {
    if (business) {
      setFormData({...getInitialFormData()});
    }
  }, [business]);

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    let invalid = false;
    const errors: any = {};
    if (!formData.salesLastWeek || formData.salesLastWeek?.length === 0) {
      errors['salesLastWeek'] = 'Please Enter salesLastWeek';
      invalid = true;
    }
    if (
      !formData.averageSalesInADay ||
      formData.averageSalesInADay?.length === 0
    ) {
      errors['averageSalesInADay'] = 'Please Enter averageSalesInADay';
      invalid = true;
    }
    if (
      !formData.possibleWeeklyPayment ||
      formData.possibleWeeklyPayment?.length === 0
    ) {
      errors['possibleWeeklyPayment'] = 'Please Enter possibleWeeklyPayment';
      invalid = true;
    }

    if (invalid) {
      setDataFormErrors(errors);
      setLoading(false);
      return;
    }
    try {
      console.log({
        variables: {transaction: formData, id: business?.id},
      });
      await updateBusiness({
        variables: {business: {transaction: formData, id: business?.id}},
      });
      showToast({
        open: true,
        message: 'Business Transaction Data Updated Successfully',
        positive: true,
      });
      onClose();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Update Business Transactions"
      btnText="Update Transactions"
      onSubmit={async e => await onSubmit(e)}
      description="Update Business Transactions">
      <div>
        <SingleInput
          title="Sales Last Week"
          value={formData.salesLastWeek ?? ''}
          type="number"
          prefix={DEFAULT_CURRENCY}
          onChange={e => onChange(e, 'salesLastWeek')}
          error={formDataErrors?.disbursementTarget}
        />
        <SingleInput
          title="Average Sales In a Day"
          value={formData.averageSalesInADay ?? ''}
          type="number"
          prefix={DEFAULT_CURRENCY}
          // placeholder="How many clients do you want to acquire this month?"
          onChange={e => onChange(e, 'averageSalesInADay')}
          error={formDataErrors?.averageSalesInADay}
        />
        <SingleInput
          title="Possible Weekly Payment"
          value={formData.possibleWeeklyPayment ?? ''}
          type="number"
          prefix={DEFAULT_CURRENCY}
          onChange={e => onChange(e, 'possibleWeeklyPayment')}
          error={formDataErrors?.possibleWeeklyPayment}
        />
      </div>
    </Dialog>
  );
};
