import React from 'react';
import {AllDetailsGroup} from '../../components/LoanApplicationDetail/components/AllDetailsGroup';
import {LoanApplicationDetail} from '../../components/LoanApplicationDetail/LoanApplicationDetail';

type Props = {
  path?: string;
  applicationId: string;
};

export const PortfolioDetail = ({applicationId, path}: Props) => {
  return (
    <LoanApplicationDetail applicationId={applicationId} path={path}>
      <AllDetailsGroup />
    </LoanApplicationDetail>
  );
};
