import React from 'react';
import {useImage} from '../hooks/useImage';

type Props = {
  textClassName?: string;
};
export const AppLogo = ({textClassName}: Props) => {
  const image = useImage('umflogo.webp');
  return (
    <div className="my-4">
      <div className="flex flex-row ">
        <img
          className="inline object-cover w-12 h-12 mr-2 rounded-full"
          src={image}
          alt="Profile image"
        />
        <span className={`text-gray-300 ${textClassName ?? ''}`}>
          Uganda <br />
          Microcredit Foundation
        </span>
      </div>
    </div>
  );
};
