import React, {ChangeEvent, SyntheticEvent} from 'react';

type InputProps = {
  title: string;
  placeholder?: string;
  error?: string;
  type?: string;
  value?: any;
  prefix?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const SingleInput = ({
  title,
  placeholder,
  error,
  type,
  value,
  onChange,
  prefix,
}: InputProps) => {
  return (
    <div className="grid grid-cols-3 gap-4 mt-2 items-center my-4">
      <div className="text-gray-500">{title}</div>
      <div className="flex flex-col w-full col-span-2">
        <div className="flex flex-row w-full justify-center">
          {prefix && (
            <label htmlFor={`${title}-id`} className="px-4 py-2">
              {prefix}
            </label>
          )}
          <input
            id={`${title}-id`}
            type={type}
            placeholder={placeholder ?? ''}
            onChange={onChange}
            value={value}
            className="border border-gray-300 rounded-md px-4 py-2 grow"
          />
        </div>

        {error && <span className="py-2 text-red-400">{error}</span>}
      </div>
    </div>
  );
};

SingleInput.defaultProps = {
  type: 'text',
};
