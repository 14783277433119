import React from 'react';

type VerificationLinkProps = {
  title: string;
  link: string;
};

export const VerificationLink = ({title, link}: VerificationLinkProps) => {
  return (
    <div className="flex flex-row p-2 w-full">
      <div className="text-gray-500 text-base">{title}</div>
      <a
        href={link}
        target="_blank"
        className="text-blue-600 hover:underline grow cursor-pointer px-2">
        {link}
      </a>
    </div>
  );
};
