import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import {Dialog} from '../../../components/Dialog';
import {invalidInput, validInput} from '../../../components/Toast';
import {DEFAULT_CURRENCY} from '../../../constants/constants';
import {useDisburseLoan} from '../../../hooks/useDisburseLoan';
import {formatMoney} from '../../../utils/format-money';
import {Checkbox} from '../../checkbox';
import {useLoanApplicationDetail} from '../LoanApplicationDetail';

type Props = {
  setLoading: (loading: boolean) => void;
  loanApplicationId?: string;
  open: boolean;
  onClose: () => void;
};

type DetailProps = {
  title: string;
  value?: any;
};
export const DetailRow = ({title, value}: DetailProps) => {
  return (
    <tr className="font-medium tracking-wide text-gray-500">
      <td className="uppercase pr-4 py-1 text-sm">{title}:</td>
      <td className="pr-4 py-1 font-bold text-base text-black">{value}</td>
    </tr>
  );
};

export const CheckBoxRow = ({title, value}: DetailProps) => {
  return (
    <tr className="font-medium tracking-wide text-gray-500">
      <td className="uppercase pr-4 py-1 text-sm">{title}:</td>
      <td className="pr-4 py-1 font-bold text-base text-black">
        <Checkbox
          title={value ? 'Yes' : 'No'}
          checked={value}
          setChecked={() => {}}
        />
      </td>
    </tr>
  );
};

export const DisburseLoanDialog = ({
  setLoading,
  onClose,
  open,
  loanApplicationId,
}: Props) => {
  const [otp, setOtp] = useState<string>('');
  const [inputError, setInputError] = useState<string | undefined>();

  const {disburseLoan, loading, success, message} = useDisburseLoan();
  const {loanApplication} = useLoanApplicationDetail();

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (success === false) {
      invalidInput(message || 'Loan Disbursement Failed');
    } else if (success === true) {
      validInput(message || 'Loan Disbursed Successfully');
    }
  }, [success, message]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
    if (inputError) setInputError(undefined);
  };

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      //validate data
      if (otp === '') {
        setInputError('Please enter otp');
        return;
      }
      //submit data
      await disburseLoan({variables: {code: otp, loanApplicationId}});
    } catch (e: any) {
      invalidInput(`An Error occurred when disbursing loan: ${e.message}`);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={'Disburse Loan'}
      btnText="Disburse"
      description="Enter Otp sent to your registered number"
      onSubmit={async e => await onSubmit(e)}>
      <table className="w-full text-left table-collapse">
        <tbody>
          <DetailRow
            title="Names"
            value={loanApplication?.business?.borrower?.nationalIdName}
          />
          <DetailRow
            title="Principal"
            value={formatMoney(
              loanApplication?.loanQuote?.principal,
              DEFAULT_CURRENCY,
            )}
          />
          <DetailRow
            title="Interest Rate"
            value={`${(loanApplication?.loanQuote?.interest ?? 0) * 100}%`}
          />
          <DetailRow
            title="Interest Amount"
            value={loanApplication?.loanQuote?.interestAmount}
          />
          <DetailRow
            title="Loan Term"
            value={`${loanApplication?.loanQuote?.termDays} days`}
          />
          <CheckBoxRow
            title="Accepted Terms"
            value={loanApplication?.acceptedTerms}
          />
        </tbody>
      </table>
      <>
        <label htmlFor="disburse-loan-opt-input">OTP</label>
        <input
          id="disburse-loan-opt-input"
          type="text"
          onChange={handleChange}
          className="border border-gray-300 rounded-md px-4 py-2 my-4"
        />
        {inputError && (
          <span className="py-2 px-4 text-red-600">{inputError}</span>
        )}
      </>
    </Dialog>
  );
};
