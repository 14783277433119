import React from 'react';
import {LocationMarkerIcon} from '@heroicons/react/outline';
import {TransactionsTicket} from './TransactionsTicket';
import {BusinessType} from '../../../hooks/useLoanApplication';

export const GPSLocation = ({location}: BusinessType) => {
  return (
    <div className="flex flex-col border border-gray-300 col-span-1 rounded-xl w-full p-4">
      <div className="text-blue-600 font-medium text-lg my-2">GPS Location</div>
      <div className="grid grid-cols-2 gap-2 w-full">
        <TransactionsTicket title="Latitude" description={location?.latitude} />
        <TransactionsTicket
          title="Longitude"
          description={location?.longitude}
        />
      </div>
      <a
        className="flex flex-row mt-5 cursor-pointer"
        target="_blank"
        href={`https://maps.google.com/?q=${location?.latitude},${location?.longitude}`}>
        <div className="h-5 w-5 mr-2 cursor-pointer">
          <LocationMarkerIcon className="text-blue-600" />
        </div>
        <div className="text-base text-blue-600">Open in Google Maps</div>
      </a>
    </div>
  );
};
