import {Maybe} from '../gql/graphql';

export const formatMoney = (
  amount: Maybe<number> | number | string | null | undefined,
  currency: string,
) => {
  if (!amount) {
    return amount;
  }
  let localAmount = amount;
  if (typeof localAmount === 'string')
    localAmount = parseFloat(amount as string);
  return `${currency.toLocaleUpperCase()} ${localAmount.toLocaleString()}`;
};
