import {ApolloError, useQuery} from '@apollo/client';
import {RefetchType} from './useLoanApplication';
import {LOAN_PAYMENTS_QUERY} from '../queries/loanPayments';
import {LoanPaymentObject} from '../gql/graphql';

type UseLoanPaymentsType = {
  loading: boolean;
  error: ApolloError | undefined;
  loanPayments: LoanPaymentObject[];
  refetch: RefetchType;
};

export const useLoanPayments = (id: string): UseLoanPaymentsType => {
  const {data, loading, error, refetch} = useQuery(LOAN_PAYMENTS_QUERY, {
    variables: {id},
  });

  return {
    loanPayments: data?.loanPayments,
    loading,
    error,
    refetch,
  };
};
