import {ApolloError, useQuery} from '@apollo/client';
import {LenderObject} from '../gql/graphql';
import {CURRENT_USER} from '../queries/currentUser';

type UseCurrentUserType = {
  user?: LenderObject;
  loading: boolean;
  error?: ApolloError;
};
export const useCurrentUser = (): UseCurrentUserType => {
  const {data, error, loading} = useQuery(CURRENT_USER);
  return {
    user: data?.lender,
    loading,
    error,
  };
};
