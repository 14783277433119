import React from 'react';
import {TabItem, TabItemType} from './TabItem';

type Props = {
  tabs: TabItemType[];
  onClick: (title: any) => void;
};

export const Tabs = ({tabs, onClick}: Props) => {
  return (
    <div className="flex flex-row w-full">
      {tabs.map(tab => (
        <TabItem key={tab.title} tab={tab} onClick={onClick} />
      ))}
    </div>
  );
};
