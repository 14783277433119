import React from 'react';
import {LoanApplicationType} from '../../../hooks/useLoanApplication';
import {TransactionsTicket} from './TransactionsTicket';

type AccountDetailsType = {
  loanApplication: LoanApplicationType;
};

export const AccountDetails = ({loanApplication}: AccountDetailsType) => {
  return (
    <div className="p-4 border border-gray-300 col-span-4 rounded-xl w-full">
      <div className="text-blue-600 font-medium text-lg my-2">
        Account Details
      </div>
      <div className="grid grid-cols-4 w-full">
        <TransactionsTicket
          title="NIN"
          description={loanApplication?.business?.borrower?.nin}
        />
        <TransactionsTicket
          title="Gender"
          description={loanApplication?.business?.borrower?.gender}
        />
        <TransactionsTicket
          title="DOB"
          description={loanApplication?.business?.borrower?.dateOfBirth}
        />
        <TransactionsTicket
          title="Nationality"
          description={loanApplication?.business?.borrower?.nationality}
        />
        <TransactionsTicket
          title="Name of Business"
          description={loanApplication?.business?.name}
        />
        <TransactionsTicket
          title="Type of Business"
          description={loanApplication?.business?.type}
        />
        <TransactionsTicket
          title="Years in Business"
          description={loanApplication?.business?.age}
        />
        <TransactionsTicket
          title="Number of Employees"
          description={loanApplication?.business?.numberOfEmployees}
        />
      </div>
    </div>
  );
};
