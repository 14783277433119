import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {EMAIL_PASSWORD_LOGIN} from '../mutations/auth/emailPasswordLogin';

export type LoginResponseType = {
  message: string;
  success: boolean;
  token: string | null;
};
export type UseEmailPasswordLoginType = {
  emailPasswordLogin: (options: MutationFunctionOptions) => any;
  loading: boolean;
  error?: ApolloError;
  data?: LoginResponseType;
};

export const useEmailPasswordLogin = (): UseEmailPasswordLoginType => {
  const [emailPasswordLoginHandler, {loading, error, data}] =
    useMutation(EMAIL_PASSWORD_LOGIN);
  return {
    emailPasswordLogin: emailPasswordLoginHandler,
    loading,
    error,
    data: data?.emailPasswordLogin,
  };
};
