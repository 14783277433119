import React from 'react';
import {PhoneIcon} from '@heroicons/react/outline';
import {Maybe} from '../../../gql/graphql';

type NextOfKinCardType = {
  title?: Maybe<string>;
  relationship?: Maybe<string>;
  name?: Maybe<string>;
  number?: Maybe<string>;
};

export const NextOfKinCard = ({
  title,
  relationship,
  name,
  number,
}: NextOfKinCardType) => {
  return (
    <div className="flex flex-col mt-5">
      <div className="text-base text-gray-500">{title}</div>
      <div className="grid grid-cols-7 gap-1 pt-1 justify-between">
        <div className="col-span-2 text-base text-black font-medium">
          {relationship}
        </div>
        <div className="col-span-2 text-base text-black font-medium">
          {name}
        </div>
        <a
          className="col-span-2 text-base text-black font-medium"
          href={`tel:${number}`}>
          {number}
        </a>
        <a
          className="col-span-1 h-5 w-5 mx-4 cursor-pointer"
          href={`tel:${number}`}>
          <PhoneIcon className="h-full w-full text-blue-600" />
        </a>
      </div>
    </div>
  );
};
