import React from 'react';
import {DEFAULT_CURRENCY} from '../../../constants/constants';
import {Maybe} from '../../../gql/graphql';
import {LoanQuoteType} from '../../../hooks/useLoanApplication';
import {formatMoney} from '../../../utils/format-money';

type RowProps = {
  title: string;
  description: any;
};
const RowItem = ({title, description}: RowProps) => {
  return (
    <div className="grid grid-cols-1 mt-4">
      <div className="text-base text-gray-100 font-medium text-lg">{title}</div>
      <div className="text-base text-gray-200">{description}</div>
    </div>
  );
};
type LoanTermsType = {
  quote: LoanQuoteType;
  pk?: Maybe<number>;
};
export const LoanTerms = ({
  quote: {amount, principal, interestAmount, termDays},
  pk,
}: LoanTermsType) => {
  return (
    <div className="bg-blue-400 rounded-xl p-4">
      <div className="text-lg text-white font-bold mb-2 text-lg">
        Loan Terms ({pk})
      </div>
      <div className="grid grid-cols-3 w-full gap-1">
        <RowItem
          title="Loan Amount"
          description={formatMoney(principal, DEFAULT_CURRENCY)}
        />
        <RowItem
          title="Total Interest"
          description={formatMoney(interestAmount, DEFAULT_CURRENCY)}
        />
        <RowItem
          title="Total Loan"
          description={formatMoney(amount, DEFAULT_CURRENCY)}
        />
        <RowItem title="Loan Term" description={`${termDays} Days`} />
      </div>
    </div>
  );
};
