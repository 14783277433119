import {gql} from '@apollo/client';

export const UPDATE_BUSINESS_MUTATION = gql`
  mutation UpdateBusinessMutation($business: BusinessInputUpdateType) {
    updateLenderBusiness(business: $business) {
      business {
        id
      }
    }
  }
`;
