import React from 'react';

export type TabItemType = {
  title: string;
  active: boolean;
};

type Props = {
  tab: TabItemType;
  onClick: (title: string) => void;
};
export const TabItem = ({tab, onClick}: Props) => {
  const baseTextColor = tab.active
    ? 'text-blue-600 font-medium'
    : 'text-gray-400 font-normal';

  const bgColor = tab.active ? 'bg-transparent' : 'bg-transparent';
  const barColor = tab.active ? 'bg-blue-600' : 'bg-gray-100';
  return (
    <div
      className={`flex flex-col w-full p-4 cursor-pointer ${bgColor}`}
      onClick={() => onClick(tab.title)}>
      <div className={`text-base p-2 text-center ${baseTextColor}`}>
        {tab.title}
      </div>
      <div className={`h-1.5 w-full rounded-2xl mt-1 ${barColor}`} />
    </div>
  );
};
