import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {CREATE_CONTACT_ATTEMPT} from '../mutations/createContactAttempt';
import {ContactAttemptsType} from './useLoanApplication';

type UseCreateContactAttemptType = {
  loading: boolean;
  contactAttempt: ContactAttemptsType;
  error?: ApolloError;
  createContactAttempt: (options: MutationFunctionOptions) => void;
};

export const useCreateContactAttempt = (): UseCreateContactAttemptType => {
  const [createContactAttempt, {data, error, loading}] = useMutation(
    CREATE_CONTACT_ATTEMPT,
  );
  return {
    contactAttempt: data?.createContactAttempt?.contactAttempt,
    error,
    loading,
    createContactAttempt,
  };
};
