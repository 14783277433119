import {gql} from '@apollo/client';
import {LOAN_APPLICATION_FRAGMENT} from './loanApplication';

export const LOAN_PAYMENTS_QUERY = gql`
  query LoanPaymentsQuery($id: ID!) {
    loanPayments(id: $id) {
      id
      createdAtUtc
      amountPaid
      paidAt
      paymentType
      totalPaid
      outStandingBalance
      pk
    }
  }
`;
