import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {BusinessObject} from '../gql/graphql';
import {UPDATE_BUSINESS_MUTATION} from '../mutations/updateBusiness';

type UseUpdateBusinessType = {
  loading: boolean;
  error?: ApolloError;
  business: BusinessObject;
  updateBusiness: (options: MutationFunctionOptions) => void;
};

export const useUpdateBusiness = (): UseUpdateBusinessType => {
  const [updateBusiness, {loading, data, error}] = useMutation(
    UPDATE_BUSINESS_MUTATION,
  );

  return {
    updateBusiness,
    loading,
    error,
    business: data?.updateBusiness?.business,
  };
};
