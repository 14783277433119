import {gql} from '@apollo/client';

export const RESUBMIT_PHOTO_MUTATION = gql`
  mutation ResubmitPhotoMutation(
    $loanApplicationId: ID!
    $photos: [ResubmitPhotoType]!
  ) {
    resubmitPhoto(loanApplicationId: $loanApplicationId, photos: $photos) {
      resubmission {
        photoType
        id
      }
    }
  }
`;
