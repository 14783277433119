import {navigate} from 'gatsby';
import React, {ReactNode, useEffect, useState, useMemo} from 'react';
import _ from 'lodash';
import AccessDenied from '../../components/AccessDenied';
import {HomePageLayout} from '../../components/HomePageLayout';
import {ItemsTable} from '../../components/ItemsTable';
import {Search} from '../../components/Search';
import {Tabs} from '../../components/Tabs';
import {TitleBar} from '../../components/TitleBar';
import {invalidInput} from '../../components/Toast';
import {DEFAULT_CURRENCY, ITEMS_PER_PAGE} from '../../constants/constants';
import {
  LoanApplicationObject,
  LoanApplicationStatusesType,
} from '../../gql/graphql';
import {useActiveTab} from '../../hooks/useActiveTab';
import {useHasPagePermission} from '../../hooks/useHasPermission';
import {useLoanApplications} from '../../hooks/useLoanApplications';
import {useRefetchOnLocationChange} from '../../hooks/useRefetchOnLocationChange';
import {formatMoney} from '../../utils/format-money';
import {ActionBtn} from '../resubmitted/components/ResubmitActionBtn';
import {LoanApplicationType} from '../../hooks/useLoanApplication';
import Pagination from '../../components/Pagination';
import usePagination, {fetchingMore} from '../../hooks/usePagination';
import {useReactiveVar} from '@apollo/client';

type Props = {
  path?: string;
};

const dismissalHeaders = [
  {name: 'ID'},
  {name: 'Business Name'},
  {name: 'Client Name'},
  {name: 'Amount'},
  {name: 'Loan limit'},
  {name: 'Dismissal Date'},
  {name: 'Phone no.'},
  {name: 'Loan Officer'},
  {name: 'Action'},
];

const declinedHeaders = [
  {name: 'ID'},
  {name: 'Business Name'},
  {name: 'Client Name'},
  {name: 'Amount'},
  {name: 'Loan limit'},
  {name: 'Dismissal Date'},
  {name: 'Phone no.'},
  {name: 'Loan Officer'},
];

const SUBMITTED_FOR_DISMISSAL = 'Submitted For Dismissal';
const DECLINED = 'Declined';

export const Dismissed = ({}: Props) => {
  const [activeTab, setActiveTab] = useState<string>(SUBMITTED_FOR_DISMISSAL);

  const [headers, setHeaders] = useState(dismissalHeaders);

  const [items, setItems] = useState<{[x: string]: string | ReactNode}[]>([]);

  const [searchText, setSearchText] = useState<string>('');

  const loanApplicationStatus = useMemo(() => {
    switch (activeTab) {
      case SUBMITTED_FOR_DISMISSAL:
        return LoanApplicationStatusesType.SubmittedForDismissal;
      case DECLINED:
        return LoanApplicationStatusesType.Declined;
      default:
        return LoanApplicationStatusesType.SubmittedForDismissal;
    }
  }, [activeTab]);

  const {
    loanApplications,
    error,
    loading,
    refetch,
    totalCount,
    pageInfo,
    fetchMore,
  } = useLoanApplications([loanApplicationStatus]);

  const {currentPage, totalPages, goToNextPage, goToPreviousPage} =
    usePagination(ITEMS_PER_PAGE, totalCount, activeTab);

  const loadingMore = useReactiveVar(fetchingMore);

  const [prevApplications, setPrevApplications] = useState<
    LoanApplicationObject[] | undefined
  >([]);

  useRefetchOnLocationChange(refetch);

  useActiveTab(
    {
      setTab: setActiveTab,
      defaultTab: SUBMITTED_FOR_DISMISSAL,
      tab: activeTab,
    },
    title => {
      setHeaders(
        title === SUBMITTED_FOR_DISMISSAL ? dismissalHeaders : declinedHeaders,
      );
      setItems(
        title === SUBMITTED_FOR_DISMISSAL
          ? getDismissed(loanApplications)
          : getDeclined(loanApplications),
      );
      setActiveTab(title);
    },
  );

  const getDismissed = (
    applications: LoanApplicationType[] | undefined,
  ): any => {
    if (!applications || !Array.isArray(applications)) return [];
    return applications?.map(loanApplication => ({
      id: loanApplication?.pk,
      businessName: (
        <div
          className="px-2 text-start text-blue-500 cursor-pointer"
          onClick={() => {
            navigate(`${loanApplication.id}/detail`);
          }}>
          {loanApplication?.business?.name}
        </div>
      ),
      clientName: loanApplication?.business?.borrower?.name,
      amount: formatMoney(
        loanApplication?.loanQuote?.principal,
        DEFAULT_CURRENCY,
      ),
      limit: formatMoney(loanApplication?.loanQuote?.amount, DEFAULT_CURRENCY),
      date: new Date(loanApplication?.stage?.createdAtUtc).toLocaleDateString(),
      phone: loanApplication?.business?.borrower?.phoneNumber,
      officer: `${
        loanApplication?.finalReviewer?.fullName ??
        loanApplication?.dcoOfficer?.fullName ??
        '-'
      }`,
      action: <ActionBtn id={loanApplication.id} />,
    }));
  };

  const getDeclined = (
    applications: LoanApplicationType[] | undefined,
  ): any => {
    if (!applications || !Array.isArray(applications)) return [];
    return applications?.map(loanApplication => ({
      id: loanApplication?.pk,
      businessName: (
        <div
          className="px-2 text-start text-blue-500 cursor-pointer"
          onClick={() => {
            navigate(`${loanApplication.id}/detail`);
          }}>
          {loanApplication?.business?.name}
        </div>
      ),
      clientName: loanApplication?.business?.borrower?.name,
      amount: formatMoney(
        loanApplication?.loanQuote?.principal,
        DEFAULT_CURRENCY,
      ),
      limit: formatMoney(loanApplication?.loanQuote?.amount, DEFAULT_CURRENCY),
      date: new Date(loanApplication?.stage?.createdAtUtc).toLocaleDateString(),
      phone: loanApplication?.business?.borrower?.phoneNumber,
      officer: `${loanApplication?.finalReviewer?.fullName ?? '-'}`,
    }));
  };

  const filterItems = (
    applications?: LoanApplicationObject[],
  ): LoanApplicationObject[] => {
    if (!applications || !Array.isArray(applications)) return [];
    return applications?.filter(
      (obj: any) =>
        new RegExp(searchText, 'i').test(obj.business?.name ?? '') ||
        new RegExp(searchText, 'i').test(
          obj.business?.borrower?.phoneNumber ?? '',
        ) ||
        new RegExp(searchText, 'i').test(obj?.business?.borrower?.name ?? '') ||
        new RegExp(searchText, 'i').test(obj?.stage?.staff?.firstName ?? '') ||
        new RegExp(searchText, 'i').test(obj?.stage?.staff?.lastName ?? '') ||
        new RegExp(searchText, 'i').test(
          new Date(obj?.stage?.createdAtUtc).toLocaleDateString() ?? '',
        ),
    );
  };

  useEffect(() => {
    if (!loanApplications || !Array.isArray(loanApplications)) return;
    if (!_.isEqual(prevApplications, loanApplications)) {
      setPrevApplications(loanApplications);
      if (activeTab === SUBMITTED_FOR_DISMISSAL) {
        setItems(getDismissed(filterItems(loanApplications)));
      } else {
        setItems(getDeclined(filterItems(loanApplications)));
      }
    }
  }, [loanApplications, activeTab, searchText]);

  useEffect(() => {
    if (error) {
      console.error('loan applications', error);
      invalidInput('Error occurred when loading loan applications');
    }
  }, [error]);

  const hasPerm = useHasPagePermission();

  if (!hasPerm('/app/dismissed-loans')) return <AccessDenied />;

  return (
    <HomePageLayout loading={loading || loadingMore}>
      <div className="flex flex-col grow w-full h-full">
        <TitleBar title="Dismissed" />
        <div className="flex flex-col px-4 py-2 bg-white m-4 rounded-lg shadow-md">
          <Search
            searchText={searchText}
            onChange={e => setSearchText(e.target.value)}
          />
          <div className="w-5/12">
            <Tabs
              tabs={[
                {
                  title: SUBMITTED_FOR_DISMISSAL,
                  active: activeTab === SUBMITTED_FOR_DISMISSAL,
                },
                {title: DECLINED, active: activeTab === DECLINED},
              ]}
              onClick={title => {
                setHeaders(
                  title === SUBMITTED_FOR_DISMISSAL
                    ? dismissalHeaders
                    : declinedHeaders,
                );
                setItems(
                  title === SUBMITTED_FOR_DISMISSAL
                    ? getDismissed(filterItems(loanApplications))
                    : getDeclined(filterItems(loanApplications)),
                );
                setActiveTab(title);
              }}
            />
          </div>
          <ItemsTable items={items} headers={headers} />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalCount}
            goToNextPage={(fetchMore, endCursor) =>
              goToNextPage(fetchMore, endCursor)
            }
            goToPreviousPage={(fetchMore, startCursor) =>
              goToPreviousPage(fetchMore, startCursor)
            }
            pageInfo={pageInfo}
            fetchMore={fetchMore}
          />
        </div>
      </div>
    </HomePageLayout>
  );
};
