import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from 'react';
import {SingleInput} from './SingleInput';
import {Dialog} from './Dialog';
import {useCurrentUser} from '../hooks/useCurrentUser';
import {useUpdateLenderPassword} from '../hooks/useUpdateLenderPassword';

type Props = {
  onClose: () => void;
  open: boolean;
};

type FormDataType = {
  currentPassword: string;
  password: string;
  repeatPassword: string;
};

type FormDataErrorType = {
  currentPassword?: string;
  password?: string;
  repeatPassword?: string;
};

export const UpdatePasswordDialog = ({onClose, open}: Props) => {
  const {user} = useCurrentUser();

  const {updatePassword, loading, data, error} = useUpdateLenderPassword();

  const [formData, setFormData] = useState<FormDataType>({
    currentPassword: '',
    password: '',
    repeatPassword: '',
  });

  useEffect(() => {}, []);

  const [formDataErrors, setFormDataErrors] = useState<FormDataErrorType>({});

  const onChange = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    setFormData({...formData, [key]: e.target.value});
    setFormDataErrors({...formDataErrors, [key]: undefined});
  };

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    let invalid = false;
    if (formData.password.length === 0) {
      setFormDataErrors({
        ...formDataErrors,
        password: 'Please enter password field',
      });
      invalid = true;
    }
    if (formData.currentPassword.length === 0) {
      setFormDataErrors({
        ...formDataErrors,
        currentPassword: 'Please enter current password field',
      });
      invalid = true;
    }
    if (formData.repeatPassword.length === 0) {
      setFormDataErrors({
        ...formDataErrors,
        repeatPassword: 'Please enter password field',
      });
      invalid = true;
    }

    if (formData.repeatPassword !== formData.password) {
      setFormDataErrors({
        ...formDataErrors,
        repeatPassword: 'Please enter passwords do not match',
      });
      invalid = true;
    }
    if (invalid) {
      return;
    }

    const submitData: {[x: string]: any} = {
      password: formData.password,
      currentPassword: formData.currentPassword,
    };

    updatePassword({variables: submitData});
    // set update password message success
    // close loading dialog
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Password"
      btnText="Update Password"
      onSubmit={onSubmit}
      description="Please enter your current password to change it">
      <SingleInput
        title="Current Password"
        placeholder="current password"
        type="password"
        value={formData.currentPassword}
        onChange={e => onChange(e, 'currentPassword')}
      />
      <SingleInput
        title="New Password"
        type="password"
        value={formData.password}
        onChange={e => onChange(e, 'password')}
      />
      <SingleInput
        title="Confirm New Password"
        type="password"
        value={formData.repeatPassword}
        onChange={e => onChange(e, 'repeatPassword')}
      />
    </Dialog>
  );
};
