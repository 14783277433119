import {gql} from '@apollo/client';

export const UPDATE_LOAN_QUOTE_MUTATION = gql`
  mutation UpdateLoanQuoteMutation(
    $quote: LoanQuoteInputType!
    $loanApplicationId: ID!
  ) {
    updateLoanQuote(loanApplicationId: $loanApplicationId, quote: $quote) {
      loanQuote {
        pk
        id
        principal
        amount
        proposedAmount
        computedPrincipal
      }
    }
  }
`;
