import {MutationFunctionOptions} from '@apollo/client';
import React from 'react';
import {DISMISSAL_REASONS} from '../../../constants/constants';
import {
  LoanApplicationObject,
  LoanApplicationStatusesType,
} from '../../../gql/graphql';
import {ActionButtons} from './ActionButtons';
import {DropDown} from './DropDown';

type Props = {
  setLoanDismissalReason: any;
  updateStage: any;
  photoResubmit: any;
  stage?: LoanApplicationStatusesType;
  setDialog: (object: {
    [x: string]: {
      open?: boolean;
      data?: any;
      loading?: boolean;
    };
  }) => void;
  dialog: {
    [x: string]: {
      open?: boolean;
      data?: any;
      loading?: boolean;
    };
  };
  loanApplication: LoanApplicationObject;
  requestOtp: (options: MutationFunctionOptions | undefined) => void;
};

export const ActionButtonsGroup = ({
  setLoanDismissalReason,
  updateStage,
  photoResubmit,
  stage,
  setDialog,
  dialog,
  requestOtp,
  loanApplication,
}: Props) => {
  const dismissalBtnText = () => {
    switch (stage) {
      case LoanApplicationStatusesType.PreScreening:
      case LoanApplicationStatusesType.Resubmitted:
      case LoanApplicationStatusesType.SubmittedForDismissal:
      case LoanApplicationStatusesType.Submitted:
      case LoanApplicationStatusesType.Resubmitting:
      case LoanApplicationStatusesType.FinalReview:
        return 'Submit For Due Diligence';
      case LoanApplicationStatusesType.DueDiligence:
        return 'Submit For Final Review';
      case LoanApplicationStatusesType.DisbursementFailed:
      case LoanApplicationStatusesType.DisbursementStarted:
        return 'Return to Final Review';
      case LoanApplicationStatusesType.FinalReview:
      case LoanApplicationStatusesType.Disbursed:
      default:
        return '';
    }
  };
  const btnText = dismissalBtnText();

  const newStage = (): LoanApplicationStatusesType => {
    switch (btnText) {
      case 'Submit For Due Diligence':
        return LoanApplicationStatusesType.DueDiligence;
      case 'Submit For Final Review':
      case 'Return to Final Review':
        return LoanApplicationStatusesType.FinalReview;
      default:
        return LoanApplicationStatusesType.PreScreening;
    }
  };

  return (
    <>
      {stage && (
        <>
          <div className="w-full px-8 mt-8 cursor pointer">
            <DropDown
              placeholder="dismissal reason"
              options={DISMISSAL_REASONS}
              onChange={setLoanDismissalReason}
            />
          </div>

          <div className="w-full px-8 py-4 mt-8">
            <ActionButtons
              currentStage={stage}
              dismissal
              stage={newStage()}
              updateStage={updateStage}
              clientResubmit={async e => await photoResubmit(e)}
              nextStageText={btnText}
              disburse={stage === LoanApplicationStatusesType.FinalReview}
              setDialog={setDialog}
              dialog={dialog}
              requestOtp={requestOtp}
              loanApplication={loanApplication}
            />
          </div>
          <div className="h-16" />
        </>
      )}
    </>
  );
};
