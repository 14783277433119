import React from 'react';
import {LoanApplicationDetail} from '../../components/LoanApplicationDetail/LoanApplicationDetail';
import {AllDetailsGroup} from '../../components/LoanApplicationDetail/components/AllDetailsGroup';
import {LoanApplicationStatusesType} from '../../gql/graphql';

type Props = {
  path?: string;
  applicationId: string;
};

export const FinalReviewDetail = ({applicationId, path}: Props) => {
  return (
    <LoanApplicationDetail
      applicationId={applicationId}
      path={path}
      stage={LoanApplicationStatusesType.FinalReview}>
      <AllDetailsGroup />
    </LoanApplicationDetail>
  );
};
