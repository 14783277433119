import {ApolloError, useMutation} from '@apollo/client';
import {UPLOAD_FILE_MUTATION} from '../mutations/fileUpload';

export type UseVerifyTokenType = {
  uploadFile: (props: {phone: string; ext: string; type?: string}) => any;
  loading: boolean;
  error: ApolloError | null;
};

export const useUploadFile = () => {
  const [uploadFile, {loading, error}] = useMutation(UPLOAD_FILE_MUTATION);
  return {
    uploadFileUrl: uploadFile,
    loading,
    error,
  };
};
