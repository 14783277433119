import {gql} from '@apollo/client';
import {LOAN_APPLICATION_FRAGMENT} from './loanApplication';

export const PROFILE_MATCHES_QUERY = gql`
  ${LOAN_APPLICATION_FRAGMENT}
  query ProfileMatchesQuery($id: ID!) {
    profileMatches(id: $id) {
      ...LoanApplicationFields
    }
  }
`;
