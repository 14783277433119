import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {RESUBMIT_PHOTO_MUTATION} from '../mutations/resubmit_photo';
import {LoanApplicationType} from './useLoanApplication';

type ResubmissionType = {
  photoType: string;
  id: string;
  loanApplication: LoanApplicationType;
};

type UseResubmitPhotoType = {
  loading: boolean;
  error?: ApolloError;
  resubmission: ResubmissionType;
  resubmitPhoto: (options: MutationFunctionOptions) => void;
};

export const useResubmitPhoto = (): UseResubmitPhotoType => {
  const [resubmitPhoto, {loading, data, error}] = useMutation(
    RESUBMIT_PHOTO_MUTATION,
  );

  return {
    resubmitPhoto,
    loading,
    error,
    resubmission: data?.resubmitPhoto?.resubmission,
  };
};
