import {gql} from '@apollo/client';
import {LOAN_FIELDS} from '../mutations/disburseLoan';
import {BUSINESS_FIELDS} from './loanApplication';

export const ALL_LOANS = gql`
  ${LOAN_FIELDS}
  ${BUSINESS_FIELDS}
  query AllLoans(
    $statuses: [LoanStatesType]
    $sort: [LoanObjectSortEnum]
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    loans(
      statuses: $statuses
      sort: $sort
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      edges {
        node {
          ...LoanFields
          business {
            ...BusinessFields
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      totalCount
    }
  }
`;
