import {gql} from '@apollo/client';
import {ROLES_QUERY} from '../queries/roles';

export const CREATE_ROLE = gql`
  mutation CreateRoleMutation($name: String!, $permissions: [String]) {
    createRole(role: {name: $name, permissions: $permissions}) {
      role {
        id
        name
        createdAtUtc
        permissions
      }
    }
  }
`;

export const CREATE_ROLE_OPTIONS = {
  update(
    cache: any,
    {
      data: {
        createRole: {role},
      },
    }: any,
  ) {
    try {
      const {
        roles: {edges},
      } = cache.readQuery({query: ROLES_QUERY});
      cache.writeQuery({
        query: ROLES_QUERY,
        data: {
          roles: {
            edges: edges.concat([{node: {__typename: 'RoleObject', ...role}}]),
          },
        },
      });
    } catch (e) {
      console.error('error when updating roles', e);
    }
  },
};
