import React, {SyntheticEvent} from 'react';
import {navigate} from '@reach/router';
import {MutationFunctionOptions} from '@apollo/client';
import {
  LoanApplicationObject,
  LoanApplicationStatusesType,
} from '../../../gql/graphql';
import {useCurrentUser} from '../../../hooks/useCurrentUser';
import {invalidInput} from '../../Toast';
import {formatMoney} from '../../../utils/format-money';
import {DEFAULT_CURRENCY} from '../../../constants/constants';

type Props = {
  clientResubmit: (e: SyntheticEvent) => void;
  stage: LoanApplicationStatusesType;
  currentStage: LoanApplicationStatusesType;
  updateStage: (e: SyntheticEvent, stage: LoanApplicationStatusesType) => void;
  nextStageText?: string;
  dismissal?: boolean;
  disburse?: boolean;
  setDialog: (object: {
    [x: string]: {
      open?: boolean;
      data?: any;
      loading?: boolean;
    };
  }) => void;
  dialog: {
    [x: string]: {
      open?: boolean;
      data?: any;
      loading?: boolean;
    };
  };
  loanApplication: LoanApplicationObject;
  requestOtp: (options: MutationFunctionOptions | undefined) => void;
};

export const baseRoute = (pathname: string | undefined) => {
  if (!pathname) return;
  const regex = /\/([^\/]+)\/([^\/]+)\//;
  const matched = pathname.match(regex);
  if (matched && matched.length > 1) {
    return `/app/${matched[2]}/`;
  }
};

export const routeToList = (pathname: string) => {
  const route = baseRoute(pathname);
  if (route) navigate(route);
};

export const ActionButtons = ({
  updateStage,
  stage,
  clientResubmit,
  nextStageText,
  dismissal,
  disburse,
  dialog,
  setDialog,
  loanApplication,
  currentStage,
}: Props) => {
  const hidden =
    [
      LoanApplicationStatusesType.Approved,
      LoanApplicationStatusesType.Declined,
      LoanApplicationStatusesType.DisbursementFailed,
      LoanApplicationStatusesType.DisbursementStarted,
      LoanApplicationStatusesType.Disbursed,
    ].indexOf(currentStage) !== -1;

  const {user} = useCurrentUser();
  const userLimit = user?.disbursementLimit ?? -1;
  const requestedPrincipal = loanApplication?.loanQuote?.principal ?? 0;

  return (
    <div className={`grid grid-cols-3 gap-8`}>
      {!hidden && dismissal && (
        <button
          className="rounded-3xl bg-black px-8 py-2 text-white shadow-lg"
          onClick={async e => {
            await updateStage(
              e,
              LoanApplicationStatusesType.SubmittedForDismissal,
            );
          }}>
          Submit Loan For Dismissal
        </button>
      )}
      {!hidden && currentStage !== LoanApplicationStatusesType.FinalReview && (
        <button
          className="rounded-3xl border-blue-600 border px-8 py-2 text-blue-600 shadow-lg"
          onClick={async e => {
            await clientResubmit(e);
          }}>
          Client Resubmit
        </button>
      )}
      {nextStageText && (
        <button
          className="rounded-3xl bg-blue-600 px-8 py-2 text-white shadow-lg"
          onClick={async e => {
            await updateStage(e, stage);
          }}>
          {nextStageText}
        </button>
      )}
      {disburse && (
        <button
          className="rounded-3xl bg-blue-600 px-8 py-2 text-white shadow-lg"
          onClick={async e => {
            if (userLimit < requestedPrincipal) {
              invalidInput(
                `Your are trying to disburse ${formatMoney(
                  requestedPrincipal,
                  DEFAULT_CURRENCY,
                )} which above your disbursement limit of ${formatMoney(
                  userLimit,
                  DEFAULT_CURRENCY,
                )}`,
              );
              return;
            }
            // await requestOtp({});
            setDialog({
              ...dialog,
              disburse: {
                ...dialog?.disburse,
                open: true,
                data: loanApplication?.id,
              },
            });
          }}>
          Disburse Loan
        </button>
      )}

      {currentStage === LoanApplicationStatusesType.Disbursed &&
        !!loanApplication?.loan?.id && (
          <button
            className="rounded-3xl bg-black px-8 py-2 text-white shadow-lg"
            onClick={async e => {
              setDialog({
                ...dialog,
                addManualPayment: {...dialog?.addManualPayment, open: true},
              });
            }}>
            Add Manual Payment
          </button>
        )}
    </div>
  );
};
