import React from 'react';
import {LoanApplicationObject} from '../../../gql/graphql';
import {GPSLocation} from './GPSLocation';
import {NextOfKin} from './NextOfKin';

type Props = {
  loanApplication: LoanApplicationObject;
};
export const NextOfKinGroup = ({loanApplication}: Props) => {
  return (
    <div className="mt-8 w-full grid grid-cols-4 gap-8">
      <NextOfKin contacts={loanApplication?.business?.borrower?.nextOfKin} />
      {loanApplication?.business && (
        <GPSLocation {...loanApplication?.business} />
      )}
    </div>
  );
};
