import {gql} from '@apollo/client';
import {LOAN_APPLICATION_FRAGMENT} from '../queries/loanApplication';

export const SEARCH_LOAN_APPLICATIONS = gql`
  ${LOAN_APPLICATION_FRAGMENT}
  mutation SearchLoanApplicationsQuery(
    $searchText: String!
    $searchOption: String!
  ) {
    searchLoanApplications(
      searchText: $searchText
      searchOption: $searchOption
    ) {
      results {
        edges {
          node {
            ...LoanApplicationFields
          }
        }
      }
    }
  }
`;
