import React from 'react';
import {PageInfo} from '../gql/graphql';

type PaginationProps = {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  goToNextPage: (fetchMore: any, endCursor: string) => void;
  goToPreviousPage: (fetchMore: any, startCursor: string) => void;
  pageInfo: PageInfo;
  fetchMore: any;
};

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  totalItems,
  fetchMore,
  goToNextPage,
  goToPreviousPage,
  pageInfo,
}) => {
  return (
    <div className="flex items-center justify-between p-4 bg-white shadow">
      <div className="text-gray-700">
        <p>Total items: {totalItems}</p>
      </div>
      <div className="flex items-center">
        <button
          className={`px-2 py-1 mr-2 rounded ${
            currentPage === 1 || !pageInfo?.startCursor
              ? 'text-gray-500 bg-gray-200 cursor-not-allowed'
              : 'text-white bg-blue-500 hover:bg-blue-600'
          }`}
          disabled={currentPage === 1 || !pageInfo?.startCursor}
          onClick={() =>
            pageInfo?.startCursor &&
            goToPreviousPage(fetchMore, pageInfo?.startCursor)
          }>
          Previous
        </button>
        <p className="mr-2 text-gray-700">
          Page {currentPage} of {totalPages}
        </p>
        <button
          className={`px-2 py-1 rounded ${
            currentPage === totalPages || !pageInfo?.endCursor
              ? 'text-gray-500 bg-gray-200 cursor-not-allowed'
              : 'text-white bg-blue-500 hover:bg-blue-600'
          }`}
          disabled={currentPage === totalPages || !pageInfo?.endCursor}
          onClick={() =>
            pageInfo?.endCursor && goToNextPage(fetchMore, pageInfo?.endCursor)
          }>
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
