import {gql} from '@apollo/client';

export const CREATE_VISIT_ATTEMPT = gql`
  mutation CreateVisitAttemptMutation($visitAttempt: VisitAttemptInputType!) {
    createVisitAttempt(visitAttempt: $visitAttempt) {
      visitAttempt {
        id
        createdAtUtc
        dateVisited
        staff {
          id
          lastName
          firstName
        }
      }
    }
  }
`;
