import {SearchIcon} from '@heroicons/react/outline';
import React, {ChangeEvent} from 'react';

type Props = {
  left?: boolean;
  containerClass?: string;
  searchText?: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const Search = ({
  left,
  containerClass,
  onChange,
  searchText: value,
}: Props) => {
  const justifyEnd = left ? 'justify-start' : 'justify-end';
  return (
    <div
      className={`flex flex-row items-center ${justifyEnd} ${containerClass}`}>
      <div className="rounded-xl bg-gray-200 flex flex-row items-center px-4">
        <div className="w-5 h-5">
          <SearchIcon className="h-full w-full" />
        </div>
        <input
          type="search"
          className="p-2 bg-transparent focus:w-80 outline-0"
          placeholder="Search by"
          onChange={onChange}
          value={value}
        />
      </div>
    </div>
  );
};

Search.defaultProps = {
  containerClass: 'mt-4',
};
