import {
  ApolloError,
  MutationFunctionOptions,
  useMutation,
} from '@apollo/client';
import {DELETE_USER, DELETE_USER_OPTIONS} from '../mutations/deleteUser';

type UseDeleteRoleType = {
  deleteUser: (options: MutationFunctionOptions) => void;
  loading: boolean;
  success: boolean;
  error?: ApolloError;
};

export const useDeleteUser = (): UseDeleteRoleType => {
  const [deleteUser, {data, error, loading}] = useMutation(
    DELETE_USER,
    DELETE_USER_OPTIONS,
  );
  return {
    deleteUser,
    success: data?.deleteLender?.success,
    error,
    loading,
  };
};
