import {gql} from '@apollo/client';

export const UPDATE_LOAN_APPLICATION_STAGE = gql`
  mutation UpdateLoanApplicationStageMutation(
    $stage: LoanApplicationStageInputType!
    $loanApplicationId: ID!
  ) {
    lenderUpdateLoanApplicationStage(
      stage: $stage
      loanApplicationId: $loanApplicationId
    ) {
      stage {
        comment
        stage
        createdAtUtc
        active
      }
    }
  }
`;
