import { graphql, useStaticQuery } from "gatsby"
import { useMemo } from "react"

export function useImage(src: string) {
  const query = graphql`
    {
      images: allFile(
        filter: { internal: { mediaType: { regex: "/image/" } } }
      ) {
        edges {
          node {
            relativePath
            extension
            publicURL
          }
        }
      }
    }
  `
  const data = useStaticQuery(query)
  const match = useMemo(
    () => data.images.edges.find(({ node }: any) => src === node.relativePath),
    [data, src]
  )
  return match?.node?.publicURL ?? ""
}
